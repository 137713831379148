import React, { Fragment } from 'react'
import { Dispatch, connect } from 'react-redux'
import { Switch, Route, RouteComponentProps } from 'react-router-dom'

// GraphQL
import { Query, ApolloProvider } from 'react-apollo'

// Reducer
import { GlobalState } from './reducers'

// Actions
import * as householdActions from './actions/households'
import * as contactActions from './actions/contacts'
import * as userActions from './actions/user'
// import * as institutionActions from './actions/institution'

// Containers
import MainContainer from './v3/containers/mainContainer'
import Households from './v3/containers/clientList'
import Profile from './v3/containers/profile'
import TosModal from './v3/containers/login/tosModal'
import Logout from './v3/containers/logout'
import AdvisorSettings from './v3/containers/advisorSettings'
import Administration from './v3/containers/administration'
import InvestmentViewfinder from './v3/containers/investmentViewfinder'
import HonestConversationsExerciseV3 from './v3/containers/honestConversations/exercise'
import AdvisorMessages from './v3/containers/advisorMessages'
//IM
import StrategySearch from './v3/containers/strategySearch'
import PortfolioAnalytics from './v3/containers/im/portfolioAnalytics'
// Components
import LoaderModal from './v3/components/loaderModal'
import LinkCRMModal from './v3/components/linkCRMModal'

// Helpers
import { GetAdvisorNotifications } from './v3/helpers/queries'
import { isSalesforce } from './v3/helpers/integrations'

// Objects
import { CRMTypes, SalesforceCRM } from './objects/institution'

import HouseholdVersionRouter from './householdVersionRouter'

import { setUser } from '../src/analytics/googleTagManager'
import { PageNotFound } from './v3/containers/errors/pageNotFound'
import ErrorBoundary from './v3/containers/errors/appCrashed'
import GQLClient from './v3/helpers/gqlClient'

interface MainContainerRoutesProps {
  termsOfServiceLoading: boolean
  termsOfServiceAccepted: boolean
  userId: string
  userTypes: string
  trafficTypes: string
  v2ACL: boolean
  showLinkCRMModal: boolean
  crmLinked: boolean
  crm: CRMTypes
  accessToken: string
  showLoader: boolean
  dispatch: Dispatch<GlobalState>
}

class MainContainerRoutes extends React.Component<MainContainerRoutesProps> {
  public async componentDidMount() {
    const { dispatch } = this.props
    const { userId, userTypes, trafficTypes } = this.props
    userId && setUser(userId, userTypes, trafficTypes)
    await dispatch(userActions.getTOSandPrivacyPolicy())
    await dispatch(householdActions.getHouseholds())
    await dispatch(contactActions.getContacts())
    dispatch(contactActions.getContactsLastLogin())
    dispatch(userActions.getNdsCheck())
  }

  public render() {
    const { termsOfServiceAccepted } = this.props
    return termsOfServiceAccepted === true
      ? this.restrictedRoutes()
      : this.tosPolicyRoutes()
  }

  private mainContainerRoute = (
    Component: React.ReactNode,
    data: any,
    subscribeToMore: any,
    props?: RouteComponentProps
  ) => {
    return (
      <ErrorBoundary routeProps={props}>
        <MainContainer notifications={data} subscribeToMore={subscribeToMore}>
          {Component}
        </MainContainer>
      </ErrorBoundary>
    )
  }
  private restrictedRoutes = () => {
    const {
      userId,
      showLoader,
      crmLinked,
      crm,
      v2ACL,
      showLinkCRMModal
    } = this.props
    const salesforceSource = this.props.crm as SalesforceCRM
    if (!GQLClient.getClient()) {
      GQLClient.initializeGQL(this.props.accessToken)
    }
    return (
      <ApolloProvider client={GQLClient.getClient()}>
        <Query query={GetAdvisorNotifications} variables={{ userId }}>
          {({ data, subscribeToMore }: any) => {
            return (
              <Fragment>
                <Switch>
                  <Route
                    path='/'
                    exact={true}
                    render={(props) =>
                      this.mainContainerRoute(
                        <Households />,
                        data,
                        subscribeToMore,
                        props
                      )
                    }
                  />
                  <Route
                    path='/advisor-settings'
                    render={(props) =>
                      this.mainContainerRoute(
                        <AdvisorSettings />,
                        data,
                        subscribeToMore,
                        props
                      )
                    }
                  />
                  <Route
                    path='/administration'
                    render={(props) =>
                      this.mainContainerRoute(
                        <Administration />,
                        data,
                        subscribeToMore,
                        props
                      )
                    }
                  />
                  <Route
                    path='/profile'
                    exact={true}
                    render={(props) =>
                      this.mainContainerRoute(
                        <Profile />,
                        data,
                        subscribeToMore,
                        props
                      )
                    }
                  />
                  <Route
                    path='/advisor-messages'
                    render={(props) =>
                      this.mainContainerRoute(
                        <AdvisorMessages />,
                        data,
                        subscribeToMore,
                        props
                      )
                    }
                  />
                  <Route
                    path='/advisor-messages/:threadId'
                    render={(props) =>
                      this.mainContainerRoute(
                        <AdvisorMessages />,
                        data,
                        subscribeToMore,
                        props
                      )
                    }
                  />
                  <Route
                    path='/households/:householdFinId/honestConversations/v3/exercise/:exerciseId'
                    component={HonestConversationsExerciseV3}
                    exact={false}
                  />
                  <Route
                    path='/households/:householdFinId/investmentViewfinder/:exerciseId'
                    component={InvestmentViewfinder}
                    exact={false}
                  />
                  <Route
                    path='/strategysearch'
                    component={StrategySearch}
                    exact={true}
                  />
                  <Route
                    path='/im/:householdFinId/portfolio/analytics'
                    component={PortfolioAnalytics}
                    exact={false}
                  />
                  <Route
                    path='/households/:householdFinId'
                    render={(props) =>
                      this.mainContainerRoute(
                        <HouseholdVersionRouter />,
                        data,
                        subscribeToMore,
                        props
                      )
                    }
                  />
                  <Route path='/logout' component={Logout} />
                  <Route
                    path='/page-not-found'
                    render={(_props) => <PageNotFound redirect={true} />}
                  />
                  <Route path='*' component={PageNotFound} />
                </Switch>
                {showLoader ? <LoaderModal /> : null}
                {!crmLinked &&
                isSalesforce(salesforceSource) &&
                showLinkCRMModal ? (
                  <LinkCRMModal crm={crm} v2ACL={v2ACL} />
                ) : null}
              </Fragment>
            )
          }}
        </Query>
      </ApolloProvider>
    )
  }

  private tosPolicyRoutes = () => {
    return !this.props.termsOfServiceLoading ? (
      <MainContainer noHeader={true}>
        <Route path='/' component={TosModal} />
      </MainContainer>
    ) : null
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    termsOfServiceLoading: store.user.termsOfServiceLoading,
    termsOfServiceAccepted: store.user.termsOfServiceAccepted,
    userId: store.user.userId,
    userTypes: store.institution
      ? store.institution.isFinlifeInstitution
        ? 'Finlife'
        : 'PFM'
      : null,
    trafficTypes: store.institution
      ? store.institution.systemInstitution
        ? 'Non-advisor'
        : 'Advisor'
      : null,
    v2ACL: store.user.v2ACL,
    showLinkCRMModal: store.user.showLinkCRMModal,
    crm: store.institution.crmSource,
    crmLinked: store.user.crmLinked,
    accessToken: store.user.accessToken,
    showLoader: store.loader.showLoader
  }
}

export default connect(mapStateToProps)(MainContainerRoutes)
