import { ApolloClient } from 'apollo-client'
import { split, HttpLink, InMemoryCache } from 'apollo-boost'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'

/**
 *
 * @param accessToken
 * @returns ApolloClient
 * Taken from src/restrictedRoutes
 */

export const newApolloClient = (accessToken: string) => {
  // Create an http link:
  const httpLink = new HttpLink({
    uri: `https://${window._env_.REACT_APP_ASYNC_URL}`,
    headers: {
      Authorization: `Bearer adviser:${accessToken}`
    }
  })

  const wsLink = new WebSocketLink({
    uri: `wss://${window._env_.REACT_APP_ASYNC_SUBSCRIPTION_URL}`,
    options: {
      timeout: 60 * 1000, // give it 60 seconds to get a kA message before retrying
      reconnect: true,
      lazy: true,
      connectionParams: {
        Authorization: `Bearer adviser:${accessToken}`
      }
    }
  })

  const link = split(
    // split based on operation type
    ({ query }) => {
      const querDoc = getMainDefinition(query)
      return (
        querDoc.kind === 'OperationDefinition' &&
        querDoc.operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )

  const cache = new InMemoryCache()
  return new ApolloClient({ cache, link })
}
