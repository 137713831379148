import React from 'react'
import Tile from '../../components/layout/tile'
import Input from '../../components/Input'
import Button from '../../components/button'

import { MoneyMindObj } from '../../../objects/moneymind'
import { ContactsInterface } from '../../../objects/contact'
import { ReactComponent as Calandar } from '../../assets/images/icons/calendar.svg'
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import DatePicker from 'react-datepicker'
import moment from 'moment'
// tslint:disable-next-line:no-submodule-imports
import 'react-datepicker/dist/react-datepicker.css'

interface AddMoneyMindProps {
  contacts: ContactsInterface
  moneymind: MoneyMindObj
  initialSelection: string
  saveFn(request: any, moneymind: MoneyMindObj): void
  cancelFn(): void
}

interface AddMoneyMindState {
  ageRange: string
  age: number
  clientId: string
  date: any
  version: string
  siteVersion: string
  totalScore: string
  yourMoneyMind: string
  myScores: MoneyMindScore
  ageError: string
  commitmentError: string
  happinessError: string
  protectionError: string
  sumError: string
  dateError: string
  disabled: boolean
}

interface MoneyMindScore {
  fear: number
  commitment: number
  happiness: number
}

class AddMoneyMind extends React.Component<
  AddMoneyMindProps,
  AddMoneyMindState
> {
  constructor(props: AddMoneyMindProps) {
    super(props)
    const { contacts, initialSelection, moneymind } = this.props
    let clientId =
      contacts && contacts.primary && initialSelection === 'primary'
        ? contacts.primary.id
        : null
    clientId =
      contacts && contacts.secondary && initialSelection === 'secondary'
        ? contacts.secondary.id
        : clientId
    let totalScore = 'Fear'
    totalScore =
      moneymind &&
      moneymind.commitment >= moneymind.happiness &&
      moneymind.commitment > moneymind.protection
        ? 'Commitment'
        : totalScore
    totalScore =
      moneymind &&
      moneymind.happiness >= moneymind.commitment &&
      moneymind.happiness > moneymind.protection
        ? 'Happiness'
        : totalScore
    const date = moneymind && moneymind.date ? moment(moneymind.date) : moment()
    this.state = {
      ageRange: moneymind ? moneymind.ageRange : null,
      age: moneymind ? moneymind.age : null,
      clientId,
      date,
      version: 'Long',
      siteVersion: '2',
      totalScore,
      yourMoneyMind: totalScore,
      myScores: {
        commitment: moneymind ? moneymind.commitment : null,
        fear: moneymind ? moneymind.protection : null,
        happiness: moneymind ? moneymind.happiness : null
      },
      ageError: '',
      commitmentError: '',
      happinessError: '',
      protectionError: '',
      sumError: '',
      dateError: '',
      disabled: true
    }
  }

  public updateAge = (event: any) => {
    let range = null
    const age = event.target.value
    range = age < 35 ? 'up to 34' : range
    range = age > 34 && age < 50 ? '35-49' : range
    range = age > 49 && age < 65 ? '50-64' : range
    range = age > 64 ? '65+' : range
    this.setState({ age, ageRange: range, disabled: false })
  }

  public updateDate = (date: any) => {
    if (date) {
      this.setState({ date, dateError: '', disabled: false })
    }
  }

  public updateCommitmentScore = (event: any) => {
    const scores = this.state.myScores
    scores.commitment = parseInt(event.target.value, 10)
    let totalScore = 'Fear'
    totalScore =
      scores.commitment >= scores.happiness && scores.commitment > scores.fear
        ? 'Commitment'
        : totalScore
    totalScore =
      scores.happiness >= scores.commitment && scores.happiness > scores.fear
        ? 'Happiness'
        : totalScore
    this.setState({
      totalScore,
      yourMoneyMind: totalScore,
      myScores: scores,
      disabled: false
    })
  }

  public updateProtectionScore = (event: any) => {
    const scores = this.state.myScores
    scores.fear = parseInt(event.target.value, 10)
    let totalScore = 'Fear'
    totalScore =
      scores.commitment >= scores.happiness && scores.commitment > scores.fear
        ? 'Commitment'
        : totalScore
    totalScore =
      scores.happiness >= scores.commitment && scores.happiness > scores.fear
        ? 'Happiness'
        : totalScore
    this.setState({
      totalScore,
      yourMoneyMind: totalScore,
      myScores: scores,
      disabled: false
    })
  }

  public updateHappinessScore = (event: any) => {
    const scores = this.state.myScores
    scores.happiness = parseInt(event.target.value, 10)
    let totalScore = 'Fear'
    totalScore =
      scores.commitment >= scores.happiness && scores.commitment > scores.fear
        ? 'Commitment'
        : totalScore
    totalScore =
      scores.happiness >= scores.commitment && scores.happiness > scores.fear
        ? 'Happiness'
        : totalScore
    this.setState({
      totalScore,
      yourMoneyMind: totalScore,
      myScores: scores,
      disabled: false
    })
  }

  public updateClientId = (event: any) => {
    const { contacts } = this.props
    const type = event.target.value
    let clientId =
      type === 'primary' && contacts && contacts.primary
        ? contacts.primary.id
        : this.state.clientId
    clientId =
      type === 'secondary' && contacts && contacts.secondary
        ? contacts.secondary.id
        : clientId
    this.setState({ clientId })
  }

  public datePicker = (error: string, errorMessage: string) => {
    return (
      <div className='form-group a-moneymind__date-w'>
        <label className='form-label form-label_mandatory'>
          <a data-tip='This is a mandatory field'>Date Game Played</a>
        </label>
        <div className={'ymm-history__label a-moneymind__date-select ' + error}>
          <Calandar />
          <span
            onClick={this.handleStopPropagation}
            className='a-moneymind__date-picker a-moneymind__date-picker--active'>
            <DatePicker
              selected={this.state.date ? this.state.date : ''}
              onChange={this.updateDate}
            />
          </span>
        </div>
        <div className='form-group__error-box'>
          {errorMessage ? (
            <span className='form-input-Error form-input-Error__message'>
              {errorMessage}
            </span>
          ) : null}
        </div>
      </div>
    )
  }

  public handleStopPropagation = (e: any) => {
    e.stopPropagation()
  }

  public saveMoneyMind = async () => {
    if (this.validateInputs()) {
      const {
        ageRange,
        age,
        clientId,
        date,
        version,
        siteVersion,
        totalScore,
        yourMoneyMind,
        myScores
      } = this.state
      const request = {
        ageRange,
        age,
        clientId,
        date: date.format().split('T')[0],
        version,
        siteVersion,
        totalScore,
        yourMoneyMind,
        myScores
      }
      this.props.saveFn(request, this.props.moneymind)
      this.setState({ disabled: true })
    }
  }

  public validateInputs = () => {
    const { age, date, myScores } = this.state
    const ageError = age && !isNaN(age) ? '' : 'form-input-Error'
    const commitmentError =
      (myScores.commitment || myScores.commitment === 0) &&
      !isNaN(myScores.commitment) &&
      (myScores.commitment >= 0 || myScores.commitment <= 100)
        ? ''
        : 'form-input-Error'
    const protectionError =
      (myScores.fear || myScores.fear === 0) &&
      !isNaN(myScores.fear) &&
      (myScores.fear >= 0 || myScores.fear <= 100)
        ? ''
        : 'form-input-Error'
    const happinessError =
      (myScores.happiness || myScores.happiness === 0) &&
      !isNaN(myScores.happiness) &&
      (myScores.happiness >= 0 || myScores.happiness <= 100)
        ? ''
        : 'form-input-Error'
    const sumError =
      !commitmentError &&
      !protectionError &&
      !happinessError &&
      myScores.happiness + myScores.commitment + myScores.fear === 100
        ? ''
        : 'form-input-Error'
    const dateError = date ? '' : 'form-input-Error date-input-Error'
    this.setState({
      ageError,
      commitmentError,
      protectionError,
      happinessError,
      sumError,
      dateError
    })
    return (
      !ageError &&
      !commitmentError &&
      !protectionError &&
      !happinessError &&
      !sumError &&
      !dateError
    )
  }

  public validateInput = (event: any) => {
    const name = event.target.name
    const type = 'sum'
    const obj = {}
    obj[name + 'Error'] = ''
    if (
      name === 'commitment' ||
      name === 'happiness' ||
      name === 'protection'
    ) {
      obj[type + 'Error'] = ''
    }
    this.setState(obj)
    return false
  }

  public leftHeader = () => {
    return (
      <div>
        <span className='a-moneymind__header'>
          <PlusIcon />
        </span>
        <span className='a-moneymind__text'>
          {this.props.moneymind ? 'EDIT RECORD' : 'ADD NEW RECORD'}
        </span>
      </div>
    )
  }

  public render() {
    const { contacts, moneymind } = this.props
    const {
      age,
      myScores,
      clientId,
      ageError,
      commitmentError,
      protectionError,
      happinessError,
      dateError,
      sumError,
      disabled
    } = this.state
    const { commitment, fear, happiness } = myScores
    const ageErrorMsg = ageError ? 'Enter a valid age' : null
    const commitmentErrorMsg = commitmentError
      ? 'Enter a valid number between 0-100'
      : null
    const protectionErrorMsg = protectionError
      ? 'Enter a valid number between 0-100'
      : null
    const happinessErrorMsg = happinessError
      ? 'Enter a valid number between 0-100'
      : null
    const sumErrorMsg =
      !commitmentError && !protectionError && !happinessError && sumError
        ? 'Commitment + Protection + Happiness must equal 100'
        : null
    const dateErrorMsg = dateError ? 'Enter a valid date' : null
    const isEdit = moneymind && moneymind.id

    return (
      <Tile
        leftHeader={this.leftHeader()}
        headerStyle={{ backgroundColor: '#FAFAFA' }}
        headerBorder={true}>
        <div className='a-moneymind__edit-details-main'>
          <div className='ymm-history-review-select'>
            {isEdit && contacts && contacts[this.props.initialSelection] ? (
              <h2>{contacts[this.props.initialSelection].firstName}</h2>
            ) : null}
            {!isEdit && contacts && contacts.primary ? (
              <label htmlFor='primaryContact'>
                <input
                  type='radio'
                  name='pickContact'
                  id='primaryContact'
                  role='radio'
                  aria-checked={false}
                  checked={contacts.primary.id === clientId}
                  value='primary'
                  onChange={this.updateClientId}
                />
                <span>{contacts.primary.firstName}</span>
              </label>
            ) : null}
            {!isEdit && contacts && contacts.secondary ? (
              <label htmlFor='secondaryContact'>
                <input
                  type='radio'
                  name='pickContact'
                  id='secondaryContact'
                  role='radio'
                  aria-checked={false}
                  checked={contacts.secondary.id === clientId}
                  value='secondary'
                  onChange={this.updateClientId}
                />
                <span>{contacts.secondary.firstName}</span>
              </label>
            ) : null}
          </div>
          <div className='a-moneymind__edit-half-w'>
            <Input
              classNames={ageError}
              onBlur={this.validateInput}
              inputType='text'
              name='age'
              mandatory={true}
              error={ageErrorMsg}
              title='Age at time'
              controlFunc={this.updateAge}
              content={age || ''}
            />
            {this.datePicker(dateError, dateErrorMsg)}
          </div>
          <div className='a-moneymind__edit-third-w'>
            <Input
              classNames={
                commitmentError || protectionError || happinessError
                  ? commitmentError
                  : sumError
              }
              onBlur={this.validateInput}
              inputType='text'
              name='commitment'
              mandatory={true}
              error={commitmentErrorMsg || sumErrorMsg}
              title='Commitment score'
              controlFunc={this.updateCommitmentScore}
              content={commitment}
            />
            <Input
              classNames={
                commitmentError || protectionError || happinessError
                  ? protectionError
                  : sumError
              }
              onBlur={this.validateInput}
              inputType='text'
              name='protection'
              mandatory={true}
              error={protectionErrorMsg}
              title='Protection score'
              controlFunc={this.updateProtectionScore}
              content={fear}
            />
            <Input
              classNames={
                commitmentError || protectionError || happinessError
                  ? happinessError
                  : sumError
              }
              onBlur={this.validateInput}
              inputType='text'
              name='happiness'
              mandatory={true}
              error={happinessErrorMsg}
              title='Happiness score'
              controlFunc={this.updateHappinessScore}
              content={happiness}
            />
          </div>
          <div className='a-moneymind__edit-btns'>
            <Button clear={true} onClick={this.props.cancelFn}>
              Cancel
            </Button>
            <Button
              disabled={disabled}
              primary={true}
              onClick={this.saveMoneyMind}>
              Save
            </Button>
          </div>
        </div>
      </Tile>
    )
  }
}

export default AddMoneyMind
