import moment from 'moment'
import uuid from 'uuid'
import { Dispatch } from 'react-redux'

import { GlobalState } from '../../reducers'
import { getTasks, syncTasks } from '../../actions/tasks'
import { taskRefetch } from './taskActionHelpers'
import { TaskObj, TaskObjState } from '../../objects/tasks'
import { ContactObj, ContactsInterface } from '../../objects/contact'
import { OfficeTeamMemberObj, OfficeTeamObj } from '../../objects/officeTeams'
import { compareStringField } from './householdPreferences'

import { cloneDeep } from 'apollo-utilities'

export const mapNewTaskObj = (
  request: TaskObj,
  householdFinId: string
): any => {
  return {
    id: uuid.v4(),
    sfId: null,
    subject: request.subject,
    description: null,
    dueDate: request.dueDate,
    createdDate: moment().format('MM-DD-YYYY'),
    completedDate: null,
    status: request?.status || 'Not Completed',
    assigneeType: 'client',
    advisorAssignee:
      !request?.primaryAssignee && !request?.secondaryAssignee
        ? request?.advisorAssignee
        : null,
    primaryAssignee: request?.primaryAssignee ?? request?.secondaryAssignee,
    secondaryAssignee:
      !request?.primaryAssignee && request?.secondaryAssignee
        ? null
        : request?.secondaryAssignee,
    assigneeNames: request.assigneeNames,
    includeInGuidebook: request.includeInGuidebook,
    householdId: householdFinId,
    crmId: null,
    crmSource: null
  }
}

const checkNullTask = (task: any) => {
  if (task.status == null && task.crmId != null) {
    return true
  }
  return false
}

export const filterNullTasks = (tasks: TaskObjState): TaskObjState => {
  const newTasks = {}
  tasks &&
    Object.keys(tasks).forEach((task: string) =>
      checkNullTask(tasks[task]) ? null : (newTasks[task] = tasks[task])
    )
  return newTasks
}

export const filterDisableTasks = (
  tasks: TaskObjState,
  disableTasks: boolean
): TaskObjState => {
  const newTasks = {}
  if (disableTasks) {
    tasks &&
      Object.keys(tasks).forEach((task: string) =>
        tasks[task].assigneeType === 'client'
          ? (newTasks[task] = tasks[task])
          : null
      )
  }
  return disableTasks ? newTasks : tasks
}

export const syncFetchTasks = async (
  householdId: string,
  dispatch: Dispatch<GlobalState>,
  isFinlifeInst: boolean,
  isEnableCRMTasks: boolean,
  isTasksV2Enabled: string
) => {
  const getTasksAction =
    isTasksV2Enabled === 'true' && !isFinlifeInst && isEnableCRMTasks
      ? taskRefetch(householdId)
      : dispatch(getTasks(householdId))
  await getTasksAction
  dispatch(syncTasks(householdId))
  setTimeout(() => getTasksAction, 60000)
}

export const householdContactSelectOptions = (
  contacts: ContactsInterface,
  customLabelProp?: any
) => {
  const contactArray: ContactObj[][] = []
  contacts.primary?.id && contactArray.push([contacts.primary])
  contacts.secondary?.id && contactArray.push([contacts.secondary])
  if (contacts.primary?.id && contacts.secondary?.id) {
    contactArray.splice(0, 0, [contacts.primary, contacts.secondary])
  }

  return contactArray.map((contacts: ContactObj[], idx: number) => {
    let contactValue = ''
    if (contacts.length > 1) {
      contactValue = `${contacts[0].firstName} and ${contacts[1].firstName}`
      return {
        label: customLabelProp
          ? customLabelProp(
              `${contacts[0].id},${contacts[1].id}`,
              contactValue,
              null,
              contacts,
              'joint',
              idx + 1 === contactArray.length
            )
          : contactValue,
        value: contactValue
      }
    }

    return contacts.map((contact: ContactObj) => {
      contactValue = `${contact.firstName} ${contact.lastName}`
      return {
        label: customLabelProp
          ? customLabelProp(
              contact.id,
              contactValue,
              null,
              contacts,
              contactArray.length === 1 || idx == 1 ? 'primary' : 'secondary',
              idx + 1 === contactArray.length
            )
          : contactValue,
        value: contactValue
      }
    })[0]
  })
}

/**
 *
 * @param officeTeam
 * @param customLabelProp
 * Office team members may have the same name
 * Dedupe and Sort
 */
export const officeTeamSelectOptions = (
  officeTeam: OfficeTeamObj[],
  customLabelProp?: any
) => {
  const filteredTeams = officeTeam.filter(
    (officeTeam: OfficeTeamObj) => officeTeam.members?.length > 0
  )

  const officeTeamMembers: OfficeTeamMemberObj[] = []
  filteredTeams.forEach((officeTeam: OfficeTeamObj) => {
    officeTeam?.members?.forEach((member: OfficeTeamMemberObj) => {
      if (member?.user?.id) {
        const memberClone = cloneDeep(member) // clone it
        const { firstName, lastName } = memberClone.user
        memberClone.user.fullName = `${firstName} ${lastName}` // assign fullname
        officeTeamMembers.push(memberClone)
      }
    })
  })

  const sortDedupe = officeTeamMembers
    .sort((a: OfficeTeamMemberObj, b: OfficeTeamMemberObj) =>
      compareStringField(a.user.fullName, b.user.fullName)
    )
    .reduce(
      (
        p: { temp: string[]; uniques: OfficeTeamMemberObj[] },
        o: OfficeTeamMemberObj
      ) => {
        if (p.temp.indexOf(o.user.id) === -1) {
          p.uniques.push(o)
          p.temp.push(o.user.id)
        }
        return p
      },
      { temp: [], uniques: [] }
    ).uniques

  return sortDedupe.map((member: OfficeTeamMemberObj, position: number) => {
    const { user } = member
    const { fullName } = user
    const isLast = sortDedupe.length === position + 1
    return {
      label: customLabelProp
        ? customLabelProp(user.id, fullName, [member], null, 'user', isLast)
        : fullName,
      value: fullName
    }
  })
}

/**
 *
 * @param activeTask
 * @param contacts
 * @param previousAssignee
 * Check assignee name from the active task or previous assignee
 * Some tasks may not have assignee ids due to migration
 */
export const assigneeIsClient = (
  activeTask: TaskObj,
  contacts: ContactsInterface,
  previousAssignee?: string
) => {
  const taskAssignee = activeTask?.assigneeNames || previousAssignee
  const contactsAssignees = contacts && householdContactSelectOptions(contacts)

  if (
    !activeTask?.advisorAssignee &&
    (activeTask?.primaryAssignee || activeTask?.secondaryAssignee)
  ) {
    return true
  }

  return contactsAssignees?.some(
    (contact: { label: string; value: string }) =>
      contact.value === taskAssignee
  )
}

export const assigneeIsServiceMember = (
  activeTask: TaskObj,
  officeTeam: OfficeTeamObj[]
) => {
  const taskAssignee = activeTask?.assigneeNames
  const contactsAssignees = officeTeam && officeTeamSelectOptions(officeTeam)

  if (activeTask?.advisorAssignee) {
    return true
  }

  return contactsAssignees?.some(
    (contact: { label: string; value: string }) =>
      contact.value === taskAssignee
  )
}

// For CRM Advisor task, render status as is. For Native Task and CRM Client Task, render Completed or Not Completed
export const renderStatus = (task: TaskObj) => {
  if (task.crmId || task.isCrmTask) return task.status
  else return task.status !== 'Completed' ? 'Not Completed' : task.status
}
