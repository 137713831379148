import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { isEmpty } from 'lodash'

import {
  MessageBodyQuery,
  GetAdvisorMessageThreadQuery
} from '../../helpers/queries'
import MessageThreadListItem from '../../components/messages/messageThreadListItem'
import MessagesError from '../../components/messages/error'
import { MessageThreadInterface } from '../messages/messageThreadList'
import { HouseholdsState } from '../../../reducers/households'
import moment from 'moment'

export interface AdvisorMessageThreadInterface {
  household: string
  subject: string
  createdDate: string
  id: string
  messagesByParent: {
    nodes: {
      createdDate: string
    }[]
  }
}

interface AdvisorMessageThreadListProps {
  searchFilter?: string
  dashMessage?: boolean
  activeThreadId?: string
  households: HouseholdsState
  userId: string
  selectMessageThread(threadId: string, householdFinId?: string): void
  nullCase?(): React.ReactNode
  onError?(): void
}

class AdvisorMessageThreadList extends Component<
  AdvisorMessageThreadListProps
> {
  public threadList = (data: any) => {
    const {
      searchFilter,
      selectMessageThread,
      households,
      dashMessage,
      activeThreadId
    } = this.props

    let flatThreadList: any = []

    // take each household message thread array and merge into a single array
    data?.allAdvisers?.nodes[0]?.householdsByHouseholdToAdviserAdviserAndHousehold?.nodes.forEach(
      (object: any) => {
        flatThreadList.push(object.messagesByHousehold.nodes)
      }
    )

    // flatten and sort threads in descending order
    flatThreadList = flatThreadList
      .flat()
      .sort((a: MessageThreadInterface, b: MessageThreadInterface) => {
        const aDate =
          a.messagesByParent.nodes.length > 0
            ? a.messagesByParent.nodes[0].createdDate
            : a.createdDate
        const bDate =
          b.messagesByParent.nodes.length > 0
            ? b.messagesByParent.nodes[0].createdDate
            : b.createdDate
        return moment(bDate).diff(moment(aDate))
      })

    // use flatten thread list to map individual threads
    return flatThreadList.map(
      (
        {
          subject,
          createdDate,
          messagesByParent,
          id,
          household
        }: AdvisorMessageThreadInterface,
        index: number
      ) => {
        const date =
          messagesByParent.nodes.length > 0
            ? messagesByParent.nodes[0].createdDate
            : createdDate
        // handles message thread search filtering for subject and household name
        const searchCondition =
          !searchFilter ||
          (subject && subject.toLocaleLowerCase().includes(searchFilter)) ||
          (households[household]?.name &&
            households[household].name
              .toLocaleLowerCase()
              .includes(searchFilter))
        if (searchCondition && dashMessage && index < 5)
          return (
            <Query
              query={MessageBodyQuery}
              variables={{ householdFinId: household, threadId: id }}>
              {({ loading, error, data }: any) => {
                if (loading || (!error && !data) || isEmpty(data)) return null
                const {
                  adviserByAdviserSender,
                  clientByClientSender
                } = data.allMessages.nodes[data.allMessages.nodes.length - 1]
                const authorName =
                  adviserByAdviserSender && adviserByAdviserSender.name
                    ? adviserByAdviserSender.name
                    : clientByClientSender && clientByClientSender.name
                    ? clientByClientSender.name
                    : ''
                return (
                  <MessageThreadListItem
                    dashMessage={dashMessage}
                    key={index}
                    subject={subject}
                    date={date}
                    householdId={household}
                    threadId={id}
                    onClick={selectMessageThread}
                    household={households[household]}
                    sentBy={authorName}
                    activeThreadId={activeThreadId}
                  />
                )
              }}
            </Query>
          )
        else if (searchCondition && !dashMessage)
          return (
            <MessageThreadListItem
              key={index}
              subject={subject}
              date={date}
              householdId={household}
              threadId={id}
              onClick={selectMessageThread}
              household={households[household]}
              activeThreadId={activeThreadId}
            />
          )
        else return null
      }
    )
  }

  public render() {
    const { nullCase, onError, userId } = this.props
    return (
      <Query query={GetAdvisorMessageThreadQuery} variables={{ userId }}>
        {({ loading, error, data }: any) => {
          if (loading || (!error && !data)) {
            return <p className='message-thread-list-loading'>Loading...</p>
          }
          if (error || isEmpty(data)) {
            onError && onError()
            return <MessagesError className='message-thread-list-error' />
          }
          if (
            (nullCase && !data.allAdvisers) ||
            data.allAdvisers.nodes.length === 0
          ) {
            // nullCase is used by dashbaord
            return nullCase ? nullCase() : null
          } else {
            return (
              <div className='message-thread-list-w'>
                {this.threadList(data)}
              </div>
            )
          }
        }}
      </Query>
    )
  }
}

export default AdvisorMessageThreadList
