import React, { Component } from 'react'
import { Query } from 'react-apollo'
import moment from 'moment'
import { isEmpty } from 'lodash'

import { HouseholdObj } from '../../../objects/household'
import { MessageBodyQuery, GetMessageThreadQuery } from '../../helpers/queries'
import MessageThreadListItem from '../../components/messages/messageThreadListItem'
import MessagesError from '../../components/messages/error'

export interface MessageThreadInterface {
  subject: string
  createdDate: string
  id: string
  messagesByParent: {
    nodes: {
      createdDate: string
    }[]
  }
}

interface MessageThreadListProps {
  householdFinId: string
  searchFilter?: string
  dashMessage?: boolean
  activeThreadId?: string
  household: HouseholdObj
  selectMessageThread(threadId: string): void
  nullCase?(): React.ReactNode
  onError?(): void
}

class MessageThreadList extends Component<MessageThreadListProps> {
  public threadList = (data: any) => {
    const {
      searchFilter,
      selectMessageThread,
      householdFinId,
      household,
      dashMessage,
      activeThreadId
    } = this.props
    return data?.allMessages?.nodes
      .sort((a: MessageThreadInterface, b: MessageThreadInterface) => {
        const aDate =
          a.messagesByParent.nodes.length > 0
            ? a.messagesByParent.nodes[0].createdDate
            : a.createdDate
        const bDate =
          b.messagesByParent.nodes.length > 0
            ? b.messagesByParent.nodes[0].createdDate
            : b.createdDate
        return moment(bDate).diff(moment(aDate))
      })
      .map(
        (
          {
            subject,
            createdDate,
            messagesByParent,
            id
          }: MessageThreadInterface,
          index: number
        ) => {
          const date =
            messagesByParent.nodes.length > 0
              ? messagesByParent.nodes[0].createdDate
              : createdDate
          if (
            (!searchFilter ||
              (subject &&
                subject.toLocaleLowerCase().includes(searchFilter))) &&
            dashMessage &&
            index < 5
          )
            return (
              <Query
                key={id}
                query={MessageBodyQuery}
                variables={{ householdFinId, threadId: id }}>
                {({ loading, error, data }: any) => {
                  if (loading || (!error && !data) || isEmpty(data)) return null
                  const {
                    adviserByAdviserSender,
                    clientByClientSender
                  } = data.allMessages.nodes[data.allMessages.nodes.length - 1]
                  const authorName =
                    adviserByAdviserSender && adviserByAdviserSender.name
                      ? adviserByAdviserSender.name
                      : clientByClientSender && clientByClientSender.name
                      ? clientByClientSender.name
                      : ''
                  return (
                    <MessageThreadListItem
                      dashMessage={dashMessage}
                      key={index}
                      subject={subject}
                      date={date}
                      householdId={householdFinId}
                      threadId={id}
                      onClick={selectMessageThread}
                      household={household}
                      sentBy={authorName}
                      activeThreadId={activeThreadId}
                    />
                  )
                }}
              </Query>
            )
          else if (
            (!searchFilter ||
              (subject &&
                subject.toLocaleLowerCase().includes(searchFilter))) &&
            !dashMessage
          )
            return (
              <MessageThreadListItem
                key={index}
                subject={subject}
                date={date}
                householdId={householdFinId}
                threadId={id}
                onClick={selectMessageThread}
                household={household}
                activeThreadId={activeThreadId}
              />
            )
          else return null
        }
      )
  }

  public render() {
    const { householdFinId, nullCase, onError } = this.props
    return (
      <Query query={GetMessageThreadQuery} variables={{ householdFinId }}>
        {({ loading, error, data }: any) => {
          if (loading || (!error && !data)) {
            return <p className='message-thread-list-loading'>Loading...</p>
          }
          if (error || isEmpty(data)) {
            onError && onError()
            return <MessagesError className='message-thread-list-error' />
          }
          if (
            (nullCase && !data.allMessages) ||
            data.allMessages.nodes.length === 0
          ) {
            // nullCase is used by dashbaord
            return nullCase ? nullCase() : null
          } else {
            return (
              <div className='message-thread-list-w'>
                {this.threadList(data)}
              </div>
            )
          }
        }}
      </Query>
    )
  }
}

export default MessageThreadList
