import React from 'react'
import { Query } from 'react-apollo'
import { Link } from 'react-router-dom'

import { GetMultipleClientsNotificationSettingsQuery } from '../../../gql/client/settings/settingsQuery'
import {
  ClientsTaskNotificationStatus,
  SingleClientNotificationStatus
} from '../../../objects/tasks'
import { ContactsInterface, ContactObj } from '../../../objects/contact'
import { ClientNotificationSettings } from '../../../objects/notifications'

const ClientNotificationStatusWarning = (props: {
  contacts: ContactsInterface
  assignee: string
  householdFinId: string
}) => {
  const { contacts, assignee, householdFinId } = props
  const clientNotificationCheck: ClientsTaskNotificationStatus = {}
  const clientIds: string[] = []
  const contactKeys: ('primary' | 'secondary')[] = ['primary', 'secondary']

  const isContactAssigned = (contact: ContactObj) => {
    if (contact && assignee) {
      const { firstName, lastName } = contact
      if (
        assignee === `${firstName} ${lastName}` ||
        assignee.includes(`${firstName} and`) ||
        assignee.includes(`and ${firstName}`)
      )
        return true
    }
    return false
  }

  const getAssigneeWarning = (
    clientNotificationStatus: SingleClientNotificationStatus
  ) => {
    const { assigned, enabled, name, key } = clientNotificationStatus
    if (!assigned) return null
    return (
      <div className='task-add__notification-warning-container' key={key}>
        <p className='task-add__notification-warning-text'>
          {`${name} will ${
            enabled ? '' : 'not'
          } receive a notification because they are ${enabled ? '' : 'not'} an
    active GuideCenter user ${
      enabled ? 'and' : 'and/or'
    } have notifications turned ${enabled ? 'on' : 'off'}.`}
        </p>
        <p className='task-add__notification-warning-text'>
          To change this, go to{' '}
          <Link
            className='task-add__notification-warning-link'
            to={{
              pathname: `/households/${householdFinId}/clientSettings`,
              state: { contact: key }
            }}>
            Settings
          </Link>
          .
        </p>
      </div>
    )
  }

  contactKeys.forEach((key) => {
    const contactId = contacts?.[key]?.id
    if (!contactId) return
    clientNotificationCheck[contactId] = {
      key,
      name: contacts[key].firstName,
      enabled: false,
      assigned: isContactAssigned(contacts[key])
    }
    if (contacts[key].enabled) clientIds.push(contactId)
  })

  return (
    <Query
      query={GetMultipleClientsNotificationSettingsQuery}
      variables={{ clientIds }}
      fetchPolicy='cache-and-network'>
      {({ data }: { data: ClientNotificationSettings }) => {
        if (data?.allClients?.nodes) {
          const { nodes } = data.allClients
          nodes.forEach((node) => {
            const { desktopSubject, emailSubject } = node || {}
            if (desktopSubject && emailSubject) {
              const allSettings = [...desktopSubject, ...emailSubject]
              if (allSettings.includes('NEW_TASK_ASSIGNED')) {
                clientNotificationCheck[node.client].enabled = true
              }
            }
          })
        }
        const clientNotificationValues = Object.values(clientNotificationCheck)
        return (
          <div className='task-add__label task-add__label-note'>
            {clientNotificationValues.map((clientCheck) =>
              getAssigneeWarning(clientCheck)
            )}
          </div>
        )
      }}
    </Query>
  )
}
export default ClientNotificationStatusWarning
