import React, { useState } from 'react'
import notificationsIcon from '../../assets/images/icons/general/notifications.png'
import { GlobalState } from '../../../reducers'
import { connect } from 'react-redux'
import onClickOutside from 'react-onclickoutside'
import { ContactState } from '../../../reducers/contacts'
import NotificationDropdown from './notificationDropdown'
import { Query } from 'react-apollo'
import { GetAdvisorNotificationSettingsQuery } from '../../helpers/queries'
import { HouseholdsState } from '../../../reducers/households'
import {
  enabledNotifications,
  extendedNotificationsDisplayables
} from '../../helpers/notifications'
import {
  AdvisorNotifcationSettings,
  NotificationType,
  AllAdvisorNotifications
} from '../../../objects/notifications'

interface HeaderNotificationProps {
  subjects: NotificationType[]
  contacts: ContactState
  notifications: AllAdvisorNotifications
  households: HouseholdsState
  userId: string
}
/* onClickOutside hoc doesn't work well with arrow style functions */
/* eslint-disable-next-line */
function HeaderNotification({
  contacts,
  notifications,
  households,
  userId,
  subjects
}: HeaderNotificationProps): JSX.Element {
  const [showDropdown, toggleDropdown] = useState(false)
  // handler for onClickOutsideHoc
  HeaderNotification.prototype.handleClickOutside = () => toggleDropdown(false)
  let unreadCount = 0
  // all notifications are returned as an array ordered by createdDate
  const notificationsNodes = notifications?.allAdviserToNotifications?.nodes

  const enabledNotificationsList = enabledNotifications(
    subjects,
    notificationsNodes
  )

  const notificationDisplayables = extendedNotificationsDisplayables(
    households,
    contacts,
    enabledNotificationsList
  )

  notificationDisplayables.forEach((notificationBatch) => {
    if (!notificationBatch.read) unreadCount++
  })

  /**
   * setTimeout avoids race condition between redux store updates, navigation and local state update
   */
  const handleCloseAfterNav = () => {
    setTimeout(() => toggleDropdown(false), 500)
  }

  return (
    <div
      className='header__notification-icon'
      onClick={() => toggleDropdown(true)}>
      <img src={notificationsIcon} alt='Notification' />
      {Boolean(unreadCount) && (
        <span className='header__notification-icon--unread'>{unreadCount}</span>
      )}
      {showDropdown && (
        <ul className='header__notification-dropdown'>
          <NotificationDropdown
            notifications={notificationDisplayables}
            onNotificationClick={handleCloseAfterNav}
            userId={userId}
          />
        </ul>
      )}
    </div>
  )
}

const ExtendedHeaderNotification = onClickOutside(HeaderNotification, {
  handleClickOutside: () => HeaderNotification.prototype.handleClickOutside
})

const HeaderNotificationWithQuery = (
  props: Omit<HeaderNotificationProps, 'subjects'> & {
    userId: string
  }
) => {
  const { userId, ...otherProps } = props

  return (
    <Query
      query={GetAdvisorNotificationSettingsQuery}
      variables={{ userId: props.userId }}>
      {({ data }: { data: AdvisorNotifcationSettings }) => {
        const { desktopSubject = [] } = data?.allAdvisers?.nodes?.[0] || {
          desktopSubject: []
        }
        return (
          <ExtendedHeaderNotification
            subjects={desktopSubject}
            userId={userId}
            {...otherProps}
          />
        )
      }}
    </Query>
  )
}

const mapStateToProps = (store: GlobalState) => {
  return {
    contacts: store.contact,
    userId: store.user.userId,
    households: store.households
  }
}

export default connect(mapStateToProps)(HeaderNotificationWithQuery)
