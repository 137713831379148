import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router'
import { GlobalState } from '../../../reducers'
import { connect, Dispatch } from 'react-redux'

import Tile from '../../components/layout/tile'
import Heading from './heading'
import InnerContainer from '../../components/layout/innerContainer'
import Button from '../../components/button'

import ContentHeader from '../../components/layout/contentHeader'
import NullMoneyMind from './nullMoneyMind'
import NullHonestConversations from './nullHonestConversations'
import DashMoneymind from './dashMoneymind'
import DashHonestConversations from './dashHonestConversations'
import DashTasks from './dashTasks'
import DashMessages from './dashMessages'
import DashPal from './dashGoals'

import { FundedScoreObj } from '../../../objects/financialGoal'
import { ContactsInterface } from '../../../objects/contact'
import { MoneyMindInterface } from '../../../objects/moneymind'
import { HouseholdObj } from '../../../objects/household'
import { HonestConversationsExerciseObj } from '../../../objects/honestConversations'
import * as financialGoalsActions from '../../../actions/financialGoals'
import * as moneymindActions from '../../../actions/moneymind'

import AssignTeamMOdal from './assignTeamModal'
import { getTasks } from '../../../actions/tasks'
import { taskRefetch } from '../../helpers/taskActionHelpers'
import * as householdActions from '../../../actions/households'
import * as clientAccountActions from '../../../actions/clientAccounts'
import * as officeTeamsActions from '../../../actions/officeTeams'
import * as userActions from '../../../actions/user'
import { getGoalActions } from '../../../actions/financialGoalActions'
import { syncFetchTasks, filterNullTasks } from '../../helpers/tasks'

import { ReactComponent as dashboardIcon } from '../../assets/images/icons/dashboard.svg'
import ArrowRightIcon from '../../assets/images/icons/png/arrow_right_blue.png'
import { ReactComponent as NetWorthIcon } from '../../assets/images/icons/networth.svg'
import { ReactComponent as GuidecenterIcon } from '../../assets/images/icons/guidecenter.svg'
import { ReactComponent as FinancialGoalsIcon } from '../../assets/images/icons/score.svg'
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg'
import warningIcon from '../../assets/images/icons/png/ic_warning.png'

import goalActionsSelector from '../../../selectors/v3/goals'

import {
  dateFormat,
  timeFormat,
  dollarFormat,
  getNetWorthFromClientAccounts
} from '../../helpers'
import { ClientAccountObjState } from '../../../objects/clientAccount'
import { FinancialGoalActionObj } from '../../../objects/financialGoalActions'
import {
  OfficeTeamsInterface,
  OfficeTeamMemberObj,
  OfficeTeamObj
} from '../../../objects/officeTeams'
import { TaskObjState } from '../../../objects/tasks'
import HouseholdTextDisplay from '../clientList/householdTextDisplay'
import { getHonestConversationExercises } from '../../../actions/hcExercises'
import ProfilePicture from '../../components/layout/profilePicture'
import { tasksSelector, tasksSelectorV2 } from '../../../selectors/v3/tasks'

export interface ClientDashboardProps {
  dispatch?: Dispatch<GlobalState>
  match: any
  householdFinId: string
  honestConversation: HonestConversationsExerciseObj
  fundedScores: FundedScoreObj[]
  household: HouseholdObj
  contacts: ContactsInterface
  moneyminds: MoneyMindInterface
  clientAccounts: ClientAccountObjState
  officeTeams: OfficeTeamsInterface
  disableTasks: boolean
  tasks: TaskObjState
  officeAdmin: boolean
  goals: FinancialGoalActionObj[]
  showHonestConversations: boolean
  isFinlifeInstitution?: boolean
  enableCRMTasks?: boolean
}

interface ClientDashboardState {
  showAssignTeamModal: boolean
}

export class ClientDashboard extends Component<
  ClientDashboardProps,
  ClientDashboardState
> {
  constructor(props: ClientDashboardProps) {
    super(props)

    this.state = {
      showAssignTeamModal: false
    }
  }

  public async componentDidMount() {
    const {
      householdFinId,
      dispatch,
      disableTasks,
      isFinlifeInstitution,
      enableCRMTasks
    } = this.props
    const { id, primaryContact, secondaryContact } = this.props.household
    const isTasksV2Enabled = window._env_.REACT_APP_CONNECT_TO_TASKS_V2
    dispatch(userActions.getUserAcStore())
    dispatch(householdActions.getOfficeTeamByHousehold(householdFinId))
    dispatch(getGoalActions(householdFinId))
    dispatch(financialGoalsActions.getFinancialGoals(householdFinId))
    dispatch(getHonestConversationExercises(householdFinId))
    dispatch(clientAccountActions.getClientAccounts(householdFinId))
    if (disableTasks) {
      const getTasksAction =
        isTasksV2Enabled === 'true' && !isFinlifeInstitution && enableCRMTasks
          ? taskRefetch(householdFinId)
          : dispatch(getTasks(householdFinId))
      await getTasksAction
    } else {
      syncFetchTasks(
        householdFinId,
        dispatch,
        isFinlifeInstitution,
        enableCRMTasks,
        isTasksV2Enabled
      )
    }
    if (primaryContact) {
      dispatch(moneymindActions.getMoneyMind(primaryContact, id, true))
    }
    if (secondaryContact) {
      dispatch(moneymindActions.getMoneyMind(secondaryContact, id, false))
    }
    dispatch(officeTeamsActions.getOfficeTeams())
  }

  public netWorth = () => {
    const { clientAccounts, householdFinId } = this.props
    const { netWorth } = getNetWorthFromClientAccounts(clientAccounts)
    return (
      <Tile
        to={`/households/${householdFinId}/netWorth`}
        headerStyle={{ padding: '12px 10px' }}
        leftHeader={
          <Heading icon={<NetWorthIcon />} headingText={'NET WORTH'} />
        }
        rightHeader={<img src={ArrowRightIcon} alt='' role='presentation' />}
        headerBorder={false}
        anchorBorder={true}>
        <div className='c-dash__row-four-content'>
          {clientAccounts && Object.keys(clientAccounts).length > 0 ? (
            dollarFormat(netWorth, 0)
          ) : (
            <div className='c-dash__null-State' />
          )}
        </div>
      </Tile>
    )
  }

  public financialGoals = () => {
    const { fundedScores, householdFinId } = this.props
    const latestFundedScore = fundedScores?.length ? fundedScores[0] : null
    const score = latestFundedScore ? (
      Math.round(latestFundedScore.score)
    ) : (
      <div className='c-dash__null-State' />
    )
    return (
      <Tile
        to={`/households/${householdFinId}/financialGoals`}
        headerStyle={{ padding: '12px 10px' }}
        leftHeader={
          <Heading icon={<FinancialGoalsIcon />} headingText={'SCORE'} />
        }
        rightHeader={<img src={ArrowRightIcon} alt='' role='presentation' />}
        headerBorder={false}
        anchorBorder={true}>
        <div className='c-dash__row-four-content'>
          {score || <div className='c-dash__null-State' />}
        </div>
      </Tile>
    )
  }

  public guideCenter = () => {
    const { householdFinId, household } = this.props
    const { lastLogin } = household
    return (
      <Tile
        to={`/households/${householdFinId}/clientSettings`}
        headerStyle={{ padding: '12px 10px' }}
        leftHeader={
          <Heading icon={<GuidecenterIcon />} headingText={'GUIDECENTER'} />
        }
        rightHeader={<img src={ArrowRightIcon} alt='' role='presentation' />}
        headerBorder={false}
        anchorBorder={true}>
        <div className='c-dash__row-four-content'>
          {lastLogin ? (
            <HouseholdTextDisplay
              text={dateFormat(lastLogin) + ' ' + timeFormat(lastLogin)}
            />
          ) : (
            <div className='c-dash__null-State' />
          )}
        </div>
      </Tile>
    )
  }

  public openAssignTeamModal = () => {
    this.setState({ showAssignTeamModal: !this.state.showAssignTeamModal })
  }

  public setAssignedTeam = (teamId: string) => {
    this.props.dispatch(
      householdActions.setActiveOfficeTeam(this.props.householdFinId, teamId)
    )
  }

  public getAssignedTeam = (): OfficeTeamObj => {
    const { officeTeam, officeTeams } = this.props.household
    let teamAssigned: OfficeTeamObj
    const officeTeamMapped: OfficeTeamsInterface = {}
    if (officeTeams) {
      officeTeams.forEach((officeTeamObj: OfficeTeamObj, index: number) => {
        if (officeTeamObj.id === officeTeam) {
          teamAssigned = officeTeamObj
        }
        officeTeamMapped[officeTeamObj.id] = officeTeamObj
      })
    }
    return teamAssigned
  }

  public renderTeamMembers = (members: OfficeTeamMemberObj[]) => {
    const position: number = 25
    const remainingMembers = members.length - 4
    let count = 0
    return members.map((member: OfficeTeamMemberObj, index: number) => {
      const user = member.userId && member.user ? member.user : null
      if (count < 4 && member.showInGuidecenter) {
        count = count + 1
        return (
          <Fragment key={index}>
            <div
              key={index}
              className='c-dash__office-team__teams'
              style={{
                top: 0,
                left: `${(count - 1) * position}px`,
                zIndex: index
              }}>
              <span className='c-dash__office-team__team-member-photo'>
                <ProfilePicture
                  firstName={user.firstName}
                  lastName={user.lastName}
                  photo={user.photoUrl}
                  pictureSize={32}
                  isPrimary={true}
                />
              </span>
            </div>
          </Fragment>
        )
      } else if (count === 4 && member.showInGuidecenter) {
        count = count + 1
        return (
          <Fragment key={index}>
            <span
              className='c-dash__office-team__team-member-photo more'
              style={{ top: 0, left: `${4 * position}px`, zIndex: 5 }}>
              +{remainingMembers}
            </span>
          </Fragment>
        )
      } else return null
    })
  }

  public hasTeamMembers = (members: OfficeTeamMemberObj[]) => {
    const activeMembers = members.filter(
      (member: OfficeTeamMemberObj) =>
        member.userId && member.showInGuidecenter && member.user
    )
    return activeMembers.length > 0
  }

  public renderAssignedTeam = () => {
    const teamAssigned = this.getAssignedTeam()

    if (teamAssigned && this.hasTeamMembers(teamAssigned.members)) {
      const members: OfficeTeamMemberObj[] = teamAssigned.members
      return (
        <div>
          <div
            className='c-dash__office-team__teams-w'
            onClick={this.openAssignTeamModal}>
            {this.renderTeamMembers(members)}
          </div>
        </div>
      )
    } else {
      return (
        <div className='c-dash__office-team__null'>
          <div className='c-dash__office-team__warning'>
            <img src={warningIcon} alt='Warning' />
            <span>No team assigned to client.</span>
          </div>
          <div className='c-dash__office-team__btn'>
            <Button primary={true} onClick={this.openAssignTeamModal}>
              <div className='c-dash__office-team__btn--text'>
                <span>
                  <PlusIcon />
                </span>
                Assign Team
              </div>
            </Button>
          </div>
        </div>
      )
    }
  }

  public renderHeader = () => {
    return (
      <div className='c-dash__office-team'>
        <ContentHeader Icon={dashboardIcon} title={'Client Dashboard'} />
        {this.renderAssignedTeam()}
      </div>
    )
  }

  public hasHonestConversation = () => {
    const { honestConversation } = this.props
    return (
      honestConversation &&
      Object.keys(honestConversation.householdCards).length &&
      Object.values(honestConversation.householdCards).some(
        (card) => card.card.title
      )
    )
  }

  public renderHonestConversation = () => {
    const {
      contacts,
      honestConversation,
      household,
      householdFinId,
      showHonestConversations
    } = this.props
    if (!showHonestConversations) return null
    return this.hasHonestConversation() ? (
      <DashHonestConversations
        household={household}
        householdFinId={householdFinId}
        honestConversation={honestConversation}
        contacts={contacts}
      />
    ) : (
      <NullHonestConversations
        contacts={contacts}
        householdFinId={householdFinId}
      />
    )
  }

  public dashRender = () => {
    const {
      goals,
      contacts,
      moneyminds,
      householdFinId,
      tasks,
      disableTasks
    } = this.props
    let showMMNull = true
    if (moneyminds) {
      showMMNull = !(
        (moneyminds.primary && Object.keys(moneyminds.primary).length > 0) ||
        (moneyminds.secondary && Object.keys(moneyminds.secondary).length > 0)
      )
    }
    return (
      <div className='c-dash'>
        <div className='c-dash__row-w'>
          <div className='c-dash__row-four'>{this.netWorth()}</div>
          <div className='c-dash__row-four'>{this.financialGoals()}</div>
          <div className='c-dash__row-four'>
            <DashPal householdFinId={householdFinId} goals={goals} />
          </div>
          <div className='c-dash__row-four'>{this.guideCenter()}</div>
        </div>
        <div className='c-dash__row-w'>
          <div className='c-dash__row-two'>
            {moneyminds && contacts && !showMMNull ? (
              <DashMoneymind
                householdFinId={householdFinId}
                moneyminds={moneyminds}
                contacts={contacts}
              />
            ) : (
              <NullMoneyMind
                moneyminds={moneyminds}
                contacts={contacts}
                householdFinId={householdFinId}
              />
            )}
          </div>
          <div className='c-dash__row-two'>
            {this.renderHonestConversation()}
          </div>
        </div>
        <div className='c-dash__row-w'>
          <div className='c-dash__row-two'>
            <DashTasks
              householdFinId={householdFinId}
              tasks={filterNullTasks(tasks)}
              disableTasks={disableTasks}
            />
          </div>
          <div className='c-dash__row-two'>
            <DashMessages householdFinId={householdFinId} />
          </div>
        </div>
      </div>
    )
  }

  public render() {
    const { household, officeAdmin } = this.props
    const { showAssignTeamModal } = this.state
    const { officeTeams } = household
    const officeTeamMapped: OfficeTeamsInterface = {}
    if (officeTeams) {
      officeTeams.forEach((officeTeamObj: OfficeTeamObj, index: number) => {
        officeTeamMapped[officeTeamObj.id] = officeTeamObj
      })
    }
    return (
      <InnerContainer>
        {this.renderHeader()}
        {this.dashRender()}
        {showAssignTeamModal ? (
          <AssignTeamMOdal
            closeModal={this.openAssignTeamModal}
            household={household}
            chooseTeam={this.setAssignedTeam}
            officeTeams={officeTeamMapped}
            officeAdmin={officeAdmin}
          />
        ) : null}
      </InnerContainer>
    )
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const householdFinId = match?.params?.householdFinId
  const household = store.households[householdFinId]
  const { isFinlifeInstitution } = store?.institution
  const tasks =
    window._env_.REACT_APP_CONNECT_TO_TASKS_V2 === 'true'
      ? // if finlife instution is true send native tasks to component else both crm and native tasks
        isFinlifeInstitution
        ? tasksSelector(store, householdFinId)
        : tasksSelectorV2(store, householdFinId)
      : tasksSelector(store, householdFinId)
  return {
    clientAccounts:
      store.clientAccount[householdFinId] &&
      store.clientAccount[householdFinId].clientAccounts,
    fundedScores:
      store.financialGoals &&
      store.financialGoals[householdFinId] &&
      store.financialGoals[householdFinId].fundedScores,
    householdFinId,
    household,
    contacts: store.contact[householdFinId],
    moneyminds: store.moneymind[householdFinId],
    honestConversation:
      store.hcExercises[householdFinId] &&
      store.households[householdFinId] &&
      store.hcExercises[householdFinId][
        store.households[householdFinId].hcExerciseId
      ],
    officeTeams: store.officeTeams,
    tasks: tasks?.openTasks || {},
    officeAdmin: store.user.officeAdmin,
    goals: goalActionsSelector(store, householdFinId),
    disableTasks: store.institution.disableTasks || false,
    showHonestConversations:
      store.households[match?.params?.householdFinId]?.showHonestConversations,
    isFinlifeInstitution: store?.institution.isFinlifeInstitution,
    enableCRMTasks: store?.institution.enableCRMTasks
  }
}

export default withRouter(connect(mapStateToProps)(ClientDashboard))
