import React, { RefObject, Fragment, Component } from 'react'
import { Query } from 'react-apollo'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { Dispatch } from 'react-redux'

import { GlobalState } from '../../../reducers'
import MessageBodyItem from './messageBodyItem'
import MessageReply from './messageReply'
import MessageNull from './messageNull'
import { dateFormat } from '../../helpers'
import { MessageBodyQuery } from '../../helpers/queries'
import { UsersObj } from '../../../objects/user'
import { ContactsInterface } from '../../../objects/contact'
import { getVideoData } from '../../../actions/messages'
import { MessageBodyInterface } from '../../../objects/messages'

import MessagesError from './error'

interface MessageBodyProps {
  dispatch: Dispatch<GlobalState>
  householdFinId: string
  threadId: string
  userId: string
  userEmail: string
  users: UsersObj
  contacts: ContactsInterface
  error: boolean
}
class MessageBody extends Component<MessageBodyProps> {
  public messagesEnd: RefObject<HTMLDivElement> = React.createRef()
  public componentDidMount() {
    this.scrollDown()
  }
  public componentDidUpdate() {
    this.scrollDown()
  }

  public getVideoData = async (
    messageId: string,
    dataId: string,
    dataType: 'video' | 'thumbnail'
  ) => {
    const { dispatch, householdFinId, threadId } = this.props
    await dispatch(
      getVideoData(householdFinId, threadId, messageId, dataId, dataType)
    )
  }

  public scrollDown = () => {
    if (this.messagesEnd.current) {
      this.messagesEnd.current.scrollTop = this.messagesEnd.current.scrollHeight
    }
  }

  public dateDivider = (date: string) => {
    const today = moment()
    return (
      <div className='message-body__divider-w'>
        <div className='message-body__divider-line' />
        <div className='message-body__divider-date'>
          {today.isSame(date, 'day') ? 'Today' : dateFormat(date)}
        </div>
        <div className='message-body__divider-line' />
      </div>
    )
  }

  public messageList = () => {
    const { threadId, householdFinId, users, contacts } = this.props
    return (
      <Query query={MessageBodyQuery} variables={{ householdFinId, threadId }}>
        {({ loading, error, data }: any) => {
          if (loading || (!error && !data)) {
            return <p>Loading...</p>
          }
          if (error || isEmpty(data)) {
            return (
              <div className='message-body__list-error'>
                <MessagesError />
              </div>
            )
          }
          let previousDate: string
          let newDate = false
          return data.allMessages.nodes.map(
            (
              {
                id: messageId,
                thumbnailId,
                videoId,
                message,
                adviserByAdviserSender,
                clientByClientSender,
                createdDate
              }: MessageBodyInterface,
              index: number
            ) => {
              if (moment(previousDate).isSame(createdDate, 'day')) {
                newDate = false
              } else {
                newDate = true
                previousDate = createdDate
              }
              let isPrimary = true
              const authorName = adviserByAdviserSender?.name
                ? adviserByAdviserSender.name
                : clientByClientSender?.name
                ? clientByClientSender.name
                : ''
              let photo =
                users &&
                adviserByAdviserSender?.adviser &&
                users[adviserByAdviserSender?.adviser]
                  ? users[adviserByAdviserSender.adviser].photo
                  : ''
              photo =
                contacts?.primary &&
                clientByClientSender?.client === contacts.primary.id
                  ? contacts.primary.photo
                  : photo
              photo =
                contacts?.secondary &&
                clientByClientSender?.client === contacts.secondary.id
                  ? contacts.secondary.photo
                  : photo
              if (
                contacts?.secondary &&
                clientByClientSender?.client === contacts.secondary.id
              ) {
                isPrimary = false
              }

              // check if the message is a video message
              const isVideoMessage = videoId && thumbnailId ? true : false

              return (
                <Fragment key={index}>
                  {newDate ? this.dateDivider(previousDate) : null}
                  <MessageBodyItem
                    key={messageId}
                    householdFinId={householdFinId}
                    messageId={messageId}
                    threadId={threadId}
                    message={message}
                    authorName={authorName}
                    createdDate={createdDate}
                    photo={photo}
                    isPrimary={isPrimary}
                    isVideoMessage={isVideoMessage}
                    isOldVideoMessage={message?.indexOf('/VideoMessage') !== -1}
                    getThumbnailUrl={() => {
                      isVideoMessage &&
                        this.getVideoData(messageId, thumbnailId, 'thumbnail')
                    }}
                    getVideoUrl={() =>
                      this.getVideoData(messageId, videoId, 'video')
                    }
                  />
                </Fragment>
              )
            }
          )
        }}
      </Query>
    )
  }

  public nullState = () => {
    const { error } = this.props
    return (
      <div className='message-null-w message-null-w--message-page'>
        <MessageNull error={error} />
      </div>
    )
  }

  public messageBody = () => {
    const { threadId, householdFinId, userId, userEmail } = this.props
    return (
      <Fragment>
        <div className='message-body__list' ref={this.messagesEnd}>
          {this.messageList()}
        </div>
        {
          <MessageReply
            householdFinId={householdFinId}
            threadId={threadId}
            userId={userId}
            userEmail={userEmail}
            onReply={this.scrollDown}
          />
        }
      </Fragment>
    )
  }

  public render() {
    const { threadId, householdFinId, userId } = this.props
    if (!threadId) {
      return this.nullState()
    }
    return (
      <div className='message-body-w'>
        {householdFinId && threadId && userId
          ? this.messageBody()
          : this.nullState()}
      </div>
    )
  }
}

export default MessageBody
