import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split
} from 'apollo-boost'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'

const GQLClient = () => {
  let client: ApolloClient<NormalizedCacheObject>
  const cache = new InMemoryCache()

  const initializeGQL = (accessToken: string) => {
    // Create an http link:
    const httpLink = new HttpLink({
      uri: `https://${window._env_.REACT_APP_ASYNC_URL}`,
      headers: {
        Authorization: `Bearer adviser:${accessToken}`
      }
    })

    const wsLink = new WebSocketLink({
      uri: `wss://${window._env_.REACT_APP_ASYNC_SUBSCRIPTION_URL}`,
      options: {
        timeout: 60 * 1000, // give it 60 seconds to get a kA message before retrying
        reconnect: true,
        reconnectionAttempts: 3,
        lazy: true,
        connectionParams: {
          Authorization: `Bearer adviser:${accessToken}`
        }
      }
    })

    const link = split(
      // split based on operation type
      ({ query }) => {
        const querDoc = getMainDefinition(query)
        return (
          querDoc.kind === 'OperationDefinition' &&
          querDoc.operation === 'subscription'
        )
      },
      wsLink,
      httpLink
    )
    client = new ApolloClient({ cache, link })
  }

  const getClient = () => {
    return client
  }

  return { initializeGQL, getClient }
}

export default GQLClient()
