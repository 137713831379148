import api from '../v3/helpers/api'
import qs from 'query-string'
import { AxiosResponse } from 'axios'
import { User } from '@unitedcapitalfinancialadvisors/finlifeapi-objects'
import { MSDynamicsCRM } from '../objects/institution'

export const FETCH_USER: string = 'FETCH_USER'
export const FETCH_USER_PENDING: string = 'FETCH_USER_PENDING'
export const FETCH_USER_FULFILLED: string = 'FETCH_USER_FULFILLED'
export const FETCH_USER_REJECTED: string = 'FETCH_USER_REJECTED'
export const UPDATE_USER: string = 'UPDATE_USER'
export const UPDATE_USER_PENDING: string = 'UPDATE_USER_PENDING'
export const UPDATE_USER_FULFILLED: string = 'UPDATE_USER_FULFILLED'
export const UPDATE_USER_REJECTED: string = 'UPDATE_USER_REJECTED'
export const LOGIN_USER: string = 'LOGIN_USER'
export const LOGIN_USER_PENDING: string = 'LOGIN_USER_PENDING'
export const LOGIN_USER_FULFILLED: string = 'LOGIN_USER_FULFILLED'
export const LOGIN_USER_REJECTED: string = 'LOGIN_USER_REJECTED'
export const SET_ACCESS_TOKEN: string = 'SET_ACCESS_TOKEN'
export const LOGOUT_USER: string = 'LOGOUT_USER'
export const NEW_PHOTO: string = 'NEW_PHOTO'
export const NEW_PHOTO_PENDING: string = 'NEW_PHOTO_PENDING'
export const NEW_PHOTO_FULFILLED: string = 'NEW_PHOTO_FULFILLED'
export const NEW_PHOTO_REJECTED: string = 'NEW_PHOTO_REJECTED'
export const DELETE_PHOTO: string = 'DELETE_PHOTO'
export const DELETE_PHOTO_PENDING: string = 'DELETE_PHOTO_PENDING'
export const DELETE_PHOTO_FULFILLED: string = 'DELETE_PHOTO_FULFILLED'
export const DELETE_PHOTO_REJECTED: string = 'DELETE_PHOTO_REJECTED'
export const STORE_COMMUNITY_ID: string = 'STORE_COMMUNITY_ID'
export const FETCH_AUTHENTICATION_TOKENS: string = 'FETCH_AUTHENTICATION_TOKENS'
export const FETCH_AUTHENTICATION_TOKENS_FULFILLED: string =
  'FETCH_AUTHENTICATION_TOKENS_FULFILLED'
export const FETCH_TOS_AND_PRIVACY_POLICY: string =
  'FETCH_TOS_AND_PRIVACY_POLICY'
export const ACCEPT_TOS_AND_PRIVACY_POLICY: string =
  'ACCEPT_TOS_AND_PRIVACY_POLICY'
export const ACCEPT_TOS_AND_PRIVACY_POLICY_PENDING: string =
  'ACCEPT_TOS_AND_PRIVACY_POLICY_PENDING'
export const ACCEPT_TOS_AND_PRIVACY_POLICY_FULFILLED: string =
  'ACCEPT_TOS_AND_PRIVACY_POLICY_FULFILLED'
export const ACCEPT_TOS_AND_PRIVACY_POLICY_REJECTED: string =
  'ACCEPT_TOS_AND_PRIVACY_POLICY_REJECTED'

export const SAVE_SALESFORCE_SYNC_TOKEN: string = 'SAVE_SALESFORCE_SYNC_TOKEN'
export const REDTAIL_LOGIN: string = 'REDTAIL_LOGIN'
export const SYNC_USER_HOUSEHOLDS: string = 'SYNC_USER_HOUSEHOLDS'
export const PREVIEW_HOUSEHOLDS_SYNC: string = 'PREVIEW_HOUSEHOLDS_SYNC'
export const REQUEST_CRM_LINK: string = 'REQUEST_CRM_LINK'
export const SAVE_JUNXURE_SYNC_TOKEN: string = 'SAVE_JUNXURE_SYNC_TOKEN'
export const SAVE_MS_DYNAMICS_SYNC_TOKEN: string = 'SAVE_MS_DYNAMICS_SYNC_TOKEN'
export const SAVE_WEALTHBOX_API_ACCESS_TOKEN: string =
  'SAVE_WEALTHBOX_API_ACCESS_TOKEN'
export const SHOW_LINK_CRM_MODAL = 'SHOW_LINK_CRM_MODAL'
export const REMOVE_HOUSEHOLDS_FROM_SYNCED_HOUSEHOLD_LIST =
  'REMOVE_HOUSEHOLDS_FROM_SYNCED_HOUSEHOLD_LIST'
export const USER_NDS_CHECK: string = 'USER_NDS_CHECK'
export const FETCH_LINK_EMONEY = 'FETCH_LINK_EMONEY'
export const FETCH_USER_AC_STORE = 'FETCH_USER_AC_STORE'
export const UPDATE_USER_AC_STORE = 'UPDATE_USER_AC_STORE'
interface TosPrivacyPolicy {
  termsofservice: string
  privacypolicy: string
}

interface GrantTokenResponse {
  refreshToken: string
  accessToken: string
}

export const getUser = (): {
  type: string
  payload: Promise<AxiosResponse<User.Response>>
} => {
  return {
    type: FETCH_USER,
    payload: api()
      .get('/users')
      .then((response) => {
        return response
      })
  }
}

export const getUserAcStore = (): {
  type: string
  payload: Promise<AxiosResponse<boolean>>
} => {
  return {
    type: FETCH_USER_AC_STORE,
    payload: api().get('users/acstore')
  }
}

export const getNdsCheck = (): {
  type: string
  payload: Promise<AxiosResponse<boolean>>
} => {
  return {
    type: USER_NDS_CHECK,
    payload: api()
      .get('/users/ndsCheck')
      .then((response) => {
        return response
      })
  }
}

export const updateUser = (
  request: any
): { type: string; payload: Promise<AxiosResponse<User.Response>> } => {
  return {
    type: UPDATE_USER,
    payload: api()
      .put('/users', request)
      .then((response: AxiosResponse<User.Response>) => {
        return response
      })
  }
}

export const setAccessToken = (accessToken: string) => {
  return {
    type: SET_ACCESS_TOKEN,
    payload: accessToken
  }
}

export const revokeAccessToken = () => {
  // Attempt to revoke the access token completely.
  // If the access token is already revoke we want this to continue.
  return {
    type: LOGOUT_USER,
    payload: api()
      .post('/auth/revoke')
      .then((response) => response)
      .catch()
  }
}

export const addPhoto = (userId: string, fileName: string, photo: string) => {
  const body = {
    fileName,
    photo
  }
  return {
    type: NEW_PHOTO,
    payload: api()
      .post('/users/photo', body)
      .then((response) => {
        response.data.id = userId
        return response
      })
  }
}

export const deletePhoto = (userId: string) => {
  return {
    type: DELETE_PHOTO,
    payload: api()
      .delete('/users/photo')
      .then((response) => {
        response.data = {}
        response.data.id = userId
        return response
      })
  }
}

export const storeCommunityId = (id: string) => {
  return {
    type: STORE_COMMUNITY_ID,
    payload: id
  }
}

export const getAuthenticationTokens = (grantToken: string | string[]) => {
  const data: string = qs.stringify({
    grantToken,
    redirectUri: `${window.location.protocol}//${window.location.host}/auth/callback`
  })

  return {
    type: FETCH_AUTHENTICATION_TOKENS,
    payload: api()
      .post('/auth/grantToken', data)
      .then(
        (
          response: AxiosResponse<GrantTokenResponse>
        ): AxiosResponse<GrantTokenResponse> => {
          return response
        }
      )
  }
}

export const getTOSandPrivacyPolicy = () => {
  return {
    type: FETCH_TOS_AND_PRIVACY_POLICY,
    payload: api()
      .get(`/terms`)
      .then(
        (
          response: AxiosResponse<TosPrivacyPolicy>
        ): AxiosResponse<TosPrivacyPolicy> => {
          return response
        }
      )
  }
}

export const acceptTOSandPrivacyPolicy = (todayDate: string) => {
  const data: string = qs.stringify({
    termsOfServiceVersion: `${todayDate}`
  })
  return {
    type: ACCEPT_TOS_AND_PRIVACY_POLICY,
    payload: api()
      .post(`/terms`, data)
      .then(
        (
          response: AxiosResponse<TosPrivacyPolicy>
        ): AxiosResponse<TosPrivacyPolicy> => {
          return response
        }
      )
  }
}

export const saveSalesforceSyncToken = (
  grantToken: string | string[],
  translatorType: string
) => {
  const { protocol, host } = window.location
  const redirectUri = `${protocol}//${host}/auth/callback/sf`
  return {
    type: SAVE_SALESFORCE_SYNC_TOKEN,
    payload: api()
      .post(`/auth/crm/${translatorType}`, { grantToken, redirectUri })
      .then((response) => response)
  }
}

export const saveJunxureSyncToken = (userId: string) => {
  return {
    type: SAVE_JUNXURE_SYNC_TOKEN,
    payload: api()
      .post('/auth/crm/junxure', { userId })
      .then((response) => {
        return response
      })
  }
}

export const saveMSDynamicsSyncToken = (
  grantToken: string | string[],
  crmSource: MSDynamicsCRM
) => {
  const { protocol, host } = window.location
  const redirectUri = `${protocol}//${host}/auth/crm/ms`
  return {
    type: SAVE_MS_DYNAMICS_SYNC_TOKEN,
    payload: api()
      .post(`/auth/crm/${crmSource}`, { grantToken, redirectUri })
      .then((response) => {
        return response
      })
  }
}

export const saveWealthboxApiAccessToken = (apiAccessToken: string) => {
  return {
    type: SAVE_WEALTHBOX_API_ACCESS_TOKEN,
    payload: api()
      .post(`/auth/crm/wealthbox`, { apiAccessToken })
      .then((response) => {
        return response
      })
  }
}

export const loginToRedtail = (username: string, password: string) => {
  return {
    type: REDTAIL_LOGIN,
    payload: api()
      .post('/auth/crm/redtail', { username, password })
      .then((response) => {
        return response
      })
  }
}

export const syncHouseholds = (crmIds: string[]) => {
  return {
    type: SYNC_USER_HOUSEHOLDS,
    payload: api()
      .post('/users/syncHouseholds', { crmIds })
      .then((response) => {
        return response
      })
  }
}

export const previewHouseholdSync = () => {
  return {
    type: PREVIEW_HOUSEHOLDS_SYNC,
    payload: api()
      .get('/users/previewHouseholdSync', { timeout: 0 })
      .then((response): any => {
        if (
          response.data.meta &&
          response.data.meta.status >= 200 &&
          response.data.meta.status < 300
        ) {
          return response
        } else {
          return Promise.reject(response)
        }
      })
  }
}

export const removeHouseholdsFromSyncedHouseholdList = (ids: string[]) => {
  return { type: REMOVE_HOUSEHOLDS_FROM_SYNCED_HOUSEHOLD_LIST, payload: ids }
}

export const showLinkCRMModal = (display: boolean) => {
  return {
    type: SHOW_LINK_CRM_MODAL,
    payload: display
  }
}

export const requestCRMLink = () => {
  return {
    type: REQUEST_CRM_LINK
  }
}
