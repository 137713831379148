import React from 'react'
import Tile from '../../components/layout/tile'
import Input from '../../components/Input'
import Select from 'react-select'
import ArrowIcon from '../../assets/images/icons/png/arrow_right_blue.png'
import * as actions from '../../../actions/clientAccounts'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { GlobalState } from '../../../reducers'
import { connect, Dispatch } from 'react-redux'
import { ReactComponent as Calendar } from '../../assets/images/icons/calendar.svg'
import { ClientAccountObj } from '../../../objects/clientAccount'
import { dateFormat } from '../../helpers'

interface IntegrationInformationProps {
  householdFinId: string
  clientAccount: ClientAccountObj
  clientAccountId: string
  showTile: boolean
  dispatch: Dispatch<GlobalState>
}

interface IntegrationInformationState {
  clientAccount: ClientAccountObj
  currentDate: any
  lastRunDateState: any
  intSource: string
  caiId: string
  lastUpdatedSource: string
  aggregationError: string
  showTile: boolean
}

interface SelectInterface {
  value: string
  label: string
}

class IntegrationInformation extends React.Component<
  IntegrationInformationProps,
  IntegrationInformationState
> {
  constructor(props: IntegrationInformationProps) {
    super(props)
    this.state = {
      currentDate: moment(),
      lastRunDateState: moment(),
      intSource: '',
      caiId: '',
      lastUpdatedSource: '',
      aggregationError: '',
      clientAccount: props.clientAccount,
      showTile: props.showTile
    }
  }
  public inputSetter = (
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const target = e.currentTarget
    const value = target.value
    const name = target.name
    enum IntInfo {
      caiId = 'caiId',
      aggregationError = 'aggregationError'
    }
    const dynName = IntInfo[name]
    const newState = this.state
    newState[dynName] = value
    this.setState(newState)
  }
  public setDate = (date: any) => {
    this.setState({ lastRunDateState: date })
  }
  public selectOptionSetter = (selectOption: any, stateName: string) => (
    e: any
  ) => {
    const name = stateName
    const newState = this.state
    newState.clientAccount[name] = e.value
    this.setState(newState)
  }
  public handleUpdateTask = () => {
    const { clientAccountId, householdFinId } = this.props
    const { clientAccount } = this.state
    clientAccount.lastRunDate = dateFormat(this.state.lastRunDateState)
    this.props.dispatch(
      actions.updateClientAccount(
        clientAccountId,
        householdFinId,
        clientAccount
      )
    )
  }
  public updateIntegrationSource = (e: SelectInterface) => {
    const newState = this.state
    this.setState(newState)
  }
  public updateLastIntegrationSource = (e: SelectInterface) => {
    const newState = this.state
    this.setState(newState)
  }
  public hideTile = () => {
    this.setState({
      showTile: !this.state.showTile
    })
  }
  public datePicker = () => {
    const { lastRunDateState } = this.state
    return (
      <div className='form-group'>
        <label className='form-label'>Last Integration Run Date</label>
        <div className='client-account-details__datepicker'>
          <span className='client-account-details__datepicker--active'>
            <DatePicker
              selected={lastRunDateState || ''}
              onChange={this.setDate}
            />
          </span>
          <Calendar />
        </div>
      </div>
    )
  }
  public showArrow = () => {
    return (
      <div
        onClick={this.hideTile}
        className='client-account-details__arrow-down'>
        <img
          src={ArrowIcon}
          alt=''
          role='presentation'
          style={{
            transform:
              this.state.showTile === true ? 'rotate(270deg)' : 'rotate(90deg)'
          }}
        />
      </div>
    )
  }
  public isSourceEditable = () => {
    const { clientAccount } = this.state
    const source = clientAccount.source
    if (source) {
      return (
        source.replace(/-|\s/g, '').toUpperCase() === 'GUIDECENTERMANUAL' ||
        'ADVISORCENTERMANUAL'
      )
    }

    return false
  }

  public selectFields = (
    titleName: string,
    inputFieldName: string,
    contents: any,
    dataList: any,
    isEditable: boolean,
    handler: any
  ) => {
    if (isEditable === true) {
      return (
        <div className='form-group'>
          <label className='form-label'>{titleName}</label>
          <Select
            onChange={handler}
            placeholder={contents}
            options={Object.keys(dataList).map((key) => dataList[key])}
            value={contents || ''}
            className='react-select-container'
            classNamePrefix='react-select'
          />
        </div>
      )
    } else {
      return (
        <div className={'form-group client-account-details__inputs-readonly'}>
          <Input
            title={titleName}
            inputType='text'
            name={inputFieldName}
            controlFunc={null}
            content={contents}
            readOnly={true}
          />
        </div>
      )
    }
  }

  public render() {
    const { caiId, aggregationError, currentDate, clientAccount } = this.state
    const currentDateFormatted = dateFormat(currentDate)
    return (
      <div className='client-account-details-w'>
        <Tile
          headerStyle={{ backgroundColor: '#FAFAFA' }}
          leftHeader={'INTEGRATION INFORMATION'}
          headerBorder={true}
          rightHeader={this.showArrow()}>
          <div
            className='client-account-details'
            style={{ display: this.state.showTile === true ? '' : 'none' }}>
            <div
              className={
                'form-inline client-account-details__inputs client-account-details__inputs-readonly'
              }>
              <Input
                title='Source'
                inputType='text'
                name='invSource'
                controlFunc={this.inputSetter}
                content={clientAccount.source}
                readOnly={true}
              />
              <Input
                title='CAI External ID'
                inputType='text'
                name='caiId'
                controlFunc={this.inputSetter}
                content={caiId}
                readOnly={true}
              />
              <Input
                title='Last Integration Run Date'
                inputType='text'
                name='lastRunDate'
                controlFunc={null}
                content={currentDateFormatted}
                readOnly={true}
              />
              <Input
                title='Last Updated Source'
                inputType='text'
                name='updatedInvsource'
                controlFunc={this.inputSetter}
                content={caiId}
                readOnly={true}
              />
              <Input
                title='Aggregation Error'
                inputType='text'
                name='aggregationError'
                controlFunc={null}
                content={aggregationError}
                readOnly={true}
              />
            </div>
          </div>
        </Tile>
      </div>
    )
  }
}

const mapStateToProps = (
  store: GlobalState,
  { householdFinId, clientAccountId }: any
) => {
  return {
    householdFinId,
    clientAccountId
  }
}

export default connect(mapStateToProps)(IntegrationInformation)
