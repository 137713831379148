import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import Select from 'react-select'
import { withRouter } from 'react-router'
import DatePicker from 'react-datepicker'

import {
  classificationOptionList,
  taxStatusList
} from '../../../objects/clientAccount'
import { HouseholdObj } from '../../../objects/household'
import {
  dateFormat,
  formatCurrencyInput,
  sanitizeCurrency
} from '../../helpers'
import { GlobalState } from '../../../reducers'
import Modal from '../../components/layout/modal'
import Input from '../../components/Input'

import Button from '../../components/button'

import { ReactComponent as Calendar } from '../../assets/images/icons/calendar.svg'
import { ReactComponent as FileIcon } from '../../assets/images/icons/file.svg'
import { validateStringInput } from '../../helpers/netWorth'

interface CreateClientAccountModalProps {
  household: HouseholdObj
  householdFinId: string
  dispatchFunc(newClientAccount: {}): void
  closeModal(): void
}

interface CreateClientAccountModalState {
  newClientAccount: any
  balanceDate: any
  showDatePicker: boolean
  source: string
  requiredFields: string[]
  accountNicknameError: string
  accountNumberFullError: string
  accountTypeError: string
  typeError: string
  totalValueError: string
  guidebookClassificationError: string
}

const emptyAccount = {
  accountNickname: '',
  accountNumberFull: '',
  balanceDate: '',
  ownerIds: '',
  totalValueManual: '',
  accountType: '',
  taxStatus: '',
  type: '',
  guidebookClassification: ''
}

class CreateClientAccountModal extends React.Component<
  CreateClientAccountModalProps,
  CreateClientAccountModalState
> {
  constructor(props: CreateClientAccountModalProps) {
    super(props)
    this.state = {
      newClientAccount: emptyAccount,
      balanceDate: moment(),
      showDatePicker: false,
      source: 'AdvisorCenter - Manual',
      requiredFields: [
        'accountNickname',
        'accountNumberFull',
        'totalValue',
        'guidebookClassification'
      ],
      accountNicknameError: '',
      accountNumberFullError: '',
      accountTypeError: '',
      typeError: '',
      totalValueError: '',
      guidebookClassificationError: ''
    }
  }

  public componentDidMount() {
    const newState = this.state
    Object.keys(this.state.newClientAccount).forEach((key) => {
      newState.newClientAccount[key] = ''
    })
    this.setState(newState)
  }

  public handleChange = (
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const target = e.currentTarget
    const value = target.value
    const name = target.name
    const newState = this.state
    newState.newClientAccount[name] = value
    this.setState(newState)
  }

  public handleDollarChange = (
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const formatVal = formatCurrencyInput(e.currentTarget.value)
    const newState = this.state
    newState.newClientAccount[e.currentTarget.name] = formatVal
    this.setState(newState)
  }

  public setDate = (date: any) => {
    this.setState({ balanceDate: date })
  }

  public setBalanceDate = () => {
    const newState = this.state
    newState.newClientAccount.balanceDate = dateFormat(newState.balanceDate)
    this.setState(newState)
  }

  public handleAddAccountTask = () => {
    const request = this.state.newClientAccount
    const { newClientAccount } = this.state
    this.setBalanceDate()
    request.totalValueManual = sanitizeCurrency(request.totalValue)

    if (this.enableAddButton(newClientAccount)) {
      this.props.dispatchFunc(request)
    } else {
      this.validateNullInputs()
    }
  }

  public closeModal = () => {
    this.props.closeModal()
  }

  public stopProp = (e: any) => {
    e.stopPropagation()
  }

  public datePicker = () => {
    const { balanceDate } = this.state
    return (
      <div className='form-group'>
        <label className='form-label form-label_mandatory'>Balance Date</label>
        <div className='client-account-modal__datepicker'>
          <span
            onClick={this.stopProp}
            className='client-account-modal__datepicker--active'>
            <DatePicker selected={balanceDate || ''} onChange={this.setDate} />
          </span>
          <Calendar />
        </div>
      </div>
    )
  }

  public updateGuidebookClassification = (e: any) => {
    const newState = this.state
    newState.newClientAccount.guidebookClassification = e.value
    this.setState(newState)
  }

  public updateTaxStatus = (e: any) => {
    const newState = this.state
    newState.newClientAccount.taxStatus = e.value
    this.setState(newState)
  }

  public updateOwnerIds = (e: any) => {
    const newState = this.state
    newState.newClientAccount.ownerIds = e.extValue.split(',')
    this.setState(newState)
  }

  public selectFields = (
    titleName: string,
    contents: any,
    dataList: any,
    controlFunc: any
  ) => {
    const placeholderText =
      contents === null || contents === undefined || contents.length === 0
        ? 'Select ' + titleName
        : contents
    const { guidebookClassificationError } = this.state
    const styles = {}
    return (
      <Select
        styles={
          !guidebookClassificationError === false && !contents === true
            ? {
                ...styles,
                control: (base, state) => ({
                  ...base,
                  boxShadow: `0 0 0 0.1rem rgba(255, 0, 0, 0)`,
                  borderColor: `#ff0000`
                })
              }
            : {
                ...styles,
                control: (base, state) => ({
                  ...base,
                  boxShadow: state.isFocused
                    ? `0 0 0 0.1rem rgba(0, 123, 255, 0.25)`
                    : ''
                })
              }
        }
        onChange={controlFunc}
        placeholder={placeholderText}
        options={Object.keys(dataList).map((key) => dataList[key])}
        value={contents || ''}
        className='form-input__control'
        classNamePrefix='form-input'
      />
    )
  }

  public render() {
    const {
      newClientAccount,
      accountNicknameError,
      accountNumberFullError,
      accountTypeError,
      typeError,
      guidebookClassificationError,
      totalValueError
    } = this.state
    const {
      accountNickname,
      accountNumberFull,
      totalValue,
      accountType,
      type,
      taxStatus,
      guidebookClassification
    } = newClientAccount
    const ownersList = this.doNewClientAccountOwners()
    const enableAddButton: boolean = this.enableAddButton(newClientAccount)

    const accountNicknameErrorMsg = accountNicknameError
      ? 'Enter a valid account name'
      : null
    const accountNumberFullErrorMsg = accountNumberFullError
      ? 'Enter a valid account number'
      : null
    const accountTypeErrorMsg = accountTypeError
      ? 'Enter a valid account type'
      : null
    const registrationTypeErrorMsg = typeError
      ? 'Enter a valid registration type'
      : null
    const guidebookClassificationErrorMsg = guidebookClassificationError
      ? 'Select a classification.'
      : null
    const totalValueErrorMsg = totalValueError
      ? 'Select a classification.'
      : null

    return (
      <Modal
        icon={FileIcon}
        title='ADD ACCOUNT'
        closeModal={this.closeModal}
        size='L'>
        <div className='modal-body client-account-modal'>
          <div className='form-inline client-account-modal__firstrow'>
            <Input
              title='Account Nickname'
              classNames={accountNicknameError}
              onBlur={this.validateInputs}
              inputType='text'
              name='accountNickname'
              mandatory={true}
              error={accountNicknameErrorMsg}
              controlFunc={this.handleChange}
              content={accountNickname || ''}
              placeholder='Enter Account Name'
            />
            <Input
              title='Account Number'
              classNames={accountNumberFullError}
              onBlur={this.validateInputs}
              inputType='text'
              name='accountNumberFull'
              mandatory={true}
              error={accountNumberFullErrorMsg}
              controlFunc={this.handleChange}
              content={accountNumberFull || ''}
              placeholder='Enter Account Number'
            />
            <Input
              title='Total Value'
              classNames={totalValueError}
              onBlur={this.validateInputs}
              inputType='text'
              name='totalValue'
              mandatory={true}
              error={totalValueErrorMsg}
              controlFunc={this.handleDollarChange}
              content={totalValue || ''}
              dollarSign={true}
            />
          </div>
          <div className='form-inline'>
            <Input
              title='Account Type'
              classNames={accountTypeError}
              onBlur={this.validateInputs}
              inputType='text'
              name='accountType'
              error={accountTypeErrorMsg}
              controlFunc={this.handleChange}
              content={accountType || ''}
              placeholder='Enter Account Type'
            />
            <Input
              title='Registration Type'
              classNames={typeError}
              onBlur={this.validateInputs}
              inputType='text'
              name='type'
              error={registrationTypeErrorMsg}
              controlFunc={this.handleChange}
              content={type || ''}
              placeholder={'Enter Registration Type'}
            />
            <div className='form-group'>
              <label className='form-label form-label_mandatory'>
                <a data-tip='This is a mandatory field'>Classification</a>
              </label>
              {this.selectFields(
                'Classification',
                guidebookClassification,
                classificationOptionList,
                this.updateGuidebookClassification
              )}
              {guidebookClassification ? null : (
                <div className='form-group__error-box'>
                  <span className='form-input-Error form-input-Error__message'>
                    {guidebookClassificationErrorMsg}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className='form-inline client-account-modal__custom-row'>
            <div className='form-group'>
              <label className='form-label'>Tax Status</label>
              <Select
                onChange={this.updateTaxStatus}
                placeholder={
                  taxStatus.length !== 0 ? taxStatus : 'Select Tax Status'
                }
                options={Object.keys(taxStatusList).map(
                  (key) => taxStatusList[key]
                )}
                value={taxStatus || ''}
                className='react-select-container'
                classNamePrefix='react-select'
              />
            </div>
            {this.datePicker()}
            <div className='form-group'>
              <label className='form-label'>Owner</label>
              <Select
                onChange={this.updateOwnerIds}
                placeholder='Select Owner'
                options={Object.keys(ownersList).map((key) => ownersList[key])}
                className='react-select-container'
                classNamePrefix='react-select'
              />
            </div>
          </div>
          <div className='form-inline '>
            <div className='form-group client-account-modal__buttons'>
              <Button onClick={this.closeModal}>Cancel</Button>
              <Button
                primary={enableAddButton}
                onClick={this.handleAddAccountTask}>
                Add
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  private validateNullInputs = () => {
    const {
      guidebookClassification,
      totalValue,
      accountNickname,
      accountNumberFull
    } = this.state.newClientAccount
    this.setState({
      accountNicknameError: accountNickname ? '' : 'form-input-Error',
      accountNumberFullError: accountNumberFull ? '' : 'form-input-Error',
      guidebookClassificationError: guidebookClassification
        ? ''
        : 'form-input-Error',
      totalValueError: totalValue ? '' : 'form-input-Error'
    })
  }

  private validateInputs = (
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name } = e.currentTarget
    const { newClientAccount } = this.state
    const newState = this.state

    newState[name + 'Error'] =
      validateStringInput(newClientAccount[name]) === false
        ? 'form-input-Error'
        : ''
    this.setState(newState)
  }

  private enableAddButton = (newClientAccount: {}): boolean => {
    const {
      requiredFields,
      accountNicknameError,
      accountNumberFullError,
      accountTypeError,
      typeError
    } = this.state
    // check for errors
    const errors = [
      accountNicknameError,
      accountNumberFullError,
      accountTypeError,
      typeError
    ].filter((error) => {
      return Boolean(error)
    })
    // filled in requirements
    const filledFields = requiredFields.filter((requiredField) => {
      return !newClientAccount[requiredField] === false
    })

    return errors.length === 0
      ? requiredFields.length === filledFields.length
      : false
  }

  private doNewClientAccountOwners = () => {
    const { household } = this.props
    const accountOwnerList = {
      Primary: { value: 'Primary', label: 'Primary', extValue: '' },
      Secondary: { value: 'Secondary', label: 'Secondary', extValue: '' },
      Joint: { value: 'Joint', label: 'Joint', extValue: '' }
    }
    if (
      household.primaryContact !== undefined &&
      !household.primaryContact !== true
    ) {
      accountOwnerList.Primary.extValue = household.primaryContact
    }
    if (
      household.secondaryContact !== undefined &&
      !household.secondaryContact !== true
    ) {
      accountOwnerList.Secondary.extValue = household.secondaryContact
      accountOwnerList.Joint.extValue =
        household.primaryContact + ',' + household.secondaryContact
    } else {
      delete accountOwnerList.Secondary
      delete accountOwnerList.Joint
    }
    if (!accountOwnerList.Primary.extValue === true) {
      return false
    }
    return accountOwnerList
  }
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    household: store.households[match.params.householdFinId],
    clientAccount: store.clientAccount[match.params.householdFinId],
    householdFinId: match.params.householdFinId
  }
}

export default withRouter(connect(mapStateToProps)(CreateClientAccountModal))
