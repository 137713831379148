import React from 'react'
import { Link } from 'react-router-dom'
import {
  Mutation,
  MutationFn,
  MutationResult,
  MutationOptions
} from 'react-apollo'
import { GroupProfilePictures } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import {
  getClientData,
  notificationDate,
  getNotificationLink,
  unreadFromAllNotifications,
  unreadFromNotification
} from '../../helpers/notifications'
import { MarkNotificationsRead } from '../../helpers/queries'
import ProfilePicture from '../../components/layout/profilePicture'
import Button from '../../components/button'
import { NotificationItemDisplay } from './notificationItemDisplay'
import {
  DisplayableNotification,
  NotificationContent,
  NotificationTypes
} from '../../../objects/notifications'

export interface NotificationDropdownProps {
  notifications: DisplayableNotification<NotificationTypes>[]
  userId: string
  onNotificationClick?(): any
}

const getMutationOptions = (ids: string[]): MutationOptions => ({
  variables: {
    ids,
    readDate: new Date().toISOString()
  }
})

export const NotificationDropdown: React.FC<NotificationDropdownProps> = ({
  notifications,
  userId,
  onNotificationClick
}) => {
  const notificationDisplayList = (mutate: MutationFn) => {
    return notifications
      .map((notification: DisplayableNotification<NotificationTypes>) => {
        const {
          read,
          notificationByNotification: { content, createdDate, subject },
          householdContacts,
          contact
        } = notification

        const { triggerType } = content as NotificationContent

        const subjects = [
          'MESSAGE',
          'NEW_YMM',
          'NEW_HC',
          'GOAL_CHANGE',
          'NEW_PDV_FILE',
          'NEW_ACCOUNT_LINKED',
          'COMPLETE_TASK',
          'NEW_TASK_ASSIGNED'
        ]
        const link = getNotificationLink(notification)
        return subjects.includes(subject) ? (
          <Link
            to={link}
            onClick={() => {
              void handleNotificationRead(notification, mutate)
              typeof onNotificationClick === 'function' && onNotificationClick()
            }}
            key={notification.id}
            className={
              read
                ? 'header__notification-dropdown-item'
                : 'header__notification-dropdown-item header__notification-dropdown-item--unread'
            }>
            <div className='header__notification-contact-avatar'>
              {triggerType === 'household' ||
              subject === 'NEW_TASK_ASSIGNED' ? (
                <GroupProfilePictures
                  clients={getClientData(householdContacts)}
                  pictureSize={32}
                  overlap={true}
                />
              ) : (
                <ProfilePicture
                  firstName={contact?.firstName}
                  lastName={contact?.lastName}
                  photo={contact?.photo}
                  isPrimary={contact?.isPrimary}
                  pictureSize={32}
                />
              )}
            </div>
            <div className='header__notification-body'>
              <div className='header__notification-date'>
                <NotificationItemDisplay notification={notification} />
                {notificationDate(createdDate)}
              </div>
            </div>
          </Link>
        ) : null
      })
      .filter((link: any) => link)
  }

  // mark a notification (or batch) as read
  const handleNotificationRead = async (
    notification: DisplayableNotification<NotificationTypes>,
    mutate: MutationFn
  ) => {
    const unreadIds = unreadFromNotification(notification)
    if (!unreadIds.length) {
      return
    }

    const options = getMutationOptions(unreadIds)
    void mutate(options)
  }

  // mark all notifications as read
  const handleAllNotificationsRead = async (mutate: MutationFn) => {
    const unreadIds = unreadFromAllNotifications(notifications)
    if (!unreadIds.length) {
      return
    }

    const options = getMutationOptions(unreadIds)
    void mutate(options)
  }

  return (
    <Mutation mutation={MarkNotificationsRead} variables={{ userId }}>
      {(mutate: MutationFn, { loading }: MutationResult) => {
        const displayableList = notificationDisplayList(mutate)
        if (!displayableList.length) {
          return (
            <div className='header__notification-dropdown-null'>
              No Notifications.
            </div>
          )
        }
        return (
          <>
            <div className='header__notification-dropdown-list'>
              {displayableList}
            </div>
            <div className='header__notification-dropdown-mark-read'>
              <Button
                onClick={() => handleAllNotificationsRead(mutate)}
                disabled={loading}
                link={true}>
                Mark All As Read
              </Button>
            </div>
          </>
        )
      }}
    </Mutation>
  )
}

export default NotificationDropdown
