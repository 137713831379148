import * as actions from '../actions/clientFilter'
import * as officeTeamActions from '../actions/officeTeams'
import { ClientFilterObj } from '../objects/clientFilter'
import { InstitutionObj } from '../objects/institution'
import { InstitutionNameIdMap } from '../objects/institutionNameIdMap'
import * as SortHelper from '../v3/helpers/householdPreferences'

const initState: ClientFilterObj = {
  institutions: [],
  selectedInstitutions: [],
  selectedMoneyMinds: [],
  selectedOfficeTeams: [],
  officeTeams: [],
  moneyMinds: [
    { name: 'Commitment', id: 'commitment' },
    { name: 'Protection', id: 'protection' },
    { name: 'Happiness', id: 'happiness' }
  ],
  showNetWorthErrors: 'none checked'
}

const mapInstitutions = (action: any) => {
  const {
    payload: { data = [] }
  } = action || { payload: { data: [] } }
  const institutionPayload = data
  // sort institutions alphabetically ascending if more than one institution
  if (institutionPayload.length > 1) {
    institutionPayload.sort((a: InstitutionObj, b: InstitutionObj) =>
      SortHelper.compareStringField(a.name || '', b.name || '')
    )
  }
  const institutionNameIdMap: InstitutionNameIdMap[] = institutionPayload
    .map((institutionData: any) => {
      const { name, id }: InstitutionObj = institutionData ?? {
        name: null,
        id: null
      }
      return (
        name &&
        id && {
          name,
          id
        }
      )
    })
    .filter((institutionFragment: InstitutionNameIdMap) => institutionFragment)
  return institutionNameIdMap
}

const mapOfficeTeams = (action: any) => {
  const {
    payload: { data = [] }
  } = action || { payload: { data: [] } }
  const officeTeamPayload = data
  const officeTeamNameIdMap: { name: string; id: string }[] = officeTeamPayload
    .map((officeTeamData: { name: string; id: string }) => {
      const { name, id }: { name: string; id: string } = officeTeamData ?? {
        name: null,
        id: null
      }
      return (
        name &&
        id && {
          name,
          id
        }
      )
    })
    .filter((officeTeamFragment: any) => officeTeamFragment)
  return officeTeamNameIdMap
}

const ClientFilter = (state = initState, action: any): ClientFilterObj => {
  let newState = state
  switch (action.type) {
    case `${actions.SET_INSTITUTIONS}_FULFILLED`:
      return { ...newState, institutions: mapInstitutions(action) }
    case `${officeTeamActions.FETCH_OFFICE_TEAMS}_FULFILLED`:
      return { ...newState, officeTeams: mapOfficeTeams(action) }
    case actions.SET_CLIENT_LIST_FILTERS:
      const {
        selectedInstitutions,
        selectedMoneyMinds,
        selectedOfficeTeams,
        showNetWorthErrors
      } = action.payload
      return {
        ...state,
        selectedInstitutions,
        selectedMoneyMinds,
        selectedOfficeTeams,
        showNetWorthErrors
      }
    default:
      return state
  }
}

export default ClientFilter
