import React, { useState } from 'react'
import { useSubscription } from '@apollo/react-hooks'
import { AdvisorNotificationSubscription } from '../../../helpers/queries'
import GQLClient from '../../../helpers/gqlClient'
import LoaderModal from '../../../components/loaderModal'

export const SyncLoader = ({ loadComplete }: { loadComplete(): void }) => {
  const { data } = useSubscription(AdvisorNotificationSubscription, {
    client: GQLClient.getClient()
  })
  const [timeoutId, setTimeoutId] = useState(null)
  !timeoutId && setTimeoutId(setTimeout(loadComplete, 30000))
  if (data && data.notification && data.notification.content) {
    const content = JSON.parse(data.notification.content)
    if (content && content.data) {
      clearTimeout(timeoutId)
      // Ideally do a check against the status here to display that the sync was successful.
      loadComplete()
    }
  }

  return <LoaderModal />
}
